import { useEffect, useState } from 'preact/hooks';

type MediaQueryResult = {
    matches : boolean,
};

type BackwardCompatMediaQueryResult = {
    addListener : (fn : (e : MediaQueryListEvent) => void) => void,
    removeListener : (fn : (e : MediaQueryListEvent) => void) => void,

    // Modern browsers support addEventListener and removeEventListener.
    // Including them here ensures compatibility with both older and newer implementations.
    addEventListener ?: (eventName : string, fn : (e : MediaQueryListEvent) => void) => void,
    removeEventListener ?: (eventName : string, fn : (e : MediaQueryListEvent) => void) => void,
};

export const useMediaQuery = (query : string) : MediaQueryResult => {
    const [ matches, setMatches ] = useState<boolean>(() => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }

        return false;
    });

    useEffect(() => {
        const mediaQuery = window.matchMedia(query);

        const handleChange = (event : MediaQueryListEvent) : void => {
            setMatches(event.matches);
        };

        setMatches(mediaQuery.matches);

        const mediaQueryList = mediaQuery as BackwardCompatMediaQueryResult;

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', handleChange);
        } else {
            mediaQueryList.addListener(handleChange);
        }

        return () => {
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener('change', handleChange);
            } else {
                mediaQueryList.removeListener(handleChange);
            }
        };
    }, [ query ]);

    return { matches };
};
