import type { ComponentChild, JSX } from 'react';

import React, { Fragment } from 'react';

import { clsx } from '../../jsx';

import type { PopupCommonStyle, PopupConfig } from '@onetext/api';

type BasePopupTermsProps = {
    brandName ?: string,
    termsUrl ?: string,
    privacyUrl ?: string,
    className ?: string,
    children ?: ComponentChild,
    config ?: PopupConfig,
    style ?: PopupCommonStyle,
};

export const BasePopupTerms = ({
    brandName,
    termsUrl,
    privacyUrl,
    className,
    children,
    config,
    style
} : BasePopupTermsProps) : JSX.Element => {
    return (
        <p
            className={
                clsx(
                    'text-[9px] font-light leading-[1.5] text-center w-[300px] opacity-80',
                    'md:w-[320px] md:text-[10px]',
                    className
                )
            }
            style={ style }
        >
            {
                children
                    ? children
                    : (
                        <Fragment>
                            {
                                'By entering your number, you agree to receive ' +
                                `automated marketing texts from ${
                            config?.component?.terms?.brandName ?? brandName ?? '[Brand Name]'
                        } and OneText. ` +
                        'No purchase required. ' +
                        'Reply HELP for help; ' +
                        'STOP to opt out. View '
                            }
                            <a
                                href={ config?.component?.terms?.termsUrl ?? termsUrl }
                                target={ '_blank' }
                                rel={ 'noopener noreferrer' }
                                className={ 'underline' }
                            >
                                { 'Terms' }
                            </a>
                            { ' & ' }
                            <a
                                href={ config?.component?.terms?.privacyUrl ?? privacyUrl }
                                target={ '_blank' }
                                rel={ 'noopener noreferrer' }
                                className={ 'underline' }
                            >
                                { 'Privacy' }
                            </a>
                        </Fragment>
                    )
            }
        </p>
    );
};
