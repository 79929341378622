import { MEDIA_QUERY, POPUP_VIEWPORT_MODE } from '../../../constants';

import { useMediaQuery } from './use-media-query';

export const useIsDesktopMode = ({
    defaultPopupViewportMode
} : {
    defaultPopupViewportMode ?: POPUP_VIEWPORT_MODE,
}) : boolean => {
    const { matches: isDesktopMode } = useMediaQuery(MEDIA_QUERY.DESKTOP);

    if (defaultPopupViewportMode) {
        // override default isDesktopMode response with defaultPopupViewportMode passed via render function.
        return defaultPopupViewportMode === POPUP_VIEWPORT_MODE.DESKTOP;
    }

    return isDesktopMode;
};
