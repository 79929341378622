/** @jsx h */

import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { promiseTry } from '../../lib';
import { LoadingDots } from '../loading-dots';
import { QRCode } from '../qr-code';

const LINK_MODAL_STYLE = `
    .onetext-link-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        width: 100dvw;
        height: 100dvh;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        animation: fade-in 0.3s linear forwards;
        opacity: 0;
        z-index: 2147483647;
    }

    .onetext-link-modal .onetext-link-modal-alert {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100vw - 40px);
        width: 500px;
        padding: 20px;
        background: #fff;
        overflow: hidden;
        cursor: auto;
        border-radius: 4px;
        animation: pop-in 0.1s ease-in-out forwards;
        text-align: center;
        font-family: Helvetica, Arial, sans-serif;
        font-size: 16px;
    }

    .onetext-link-modal .onetext-link-modal-alert h1 {
        font-size: 18px;
        margin: 0;
        margin-bottom: 5px;
    }

    .onetext-link-modal .onetext-link-modal-alert h2 {
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
    }

    .onetext-link-modal .onetext-link-modal-alert-qr {
        display: inline-block;
        margin-top: 10px;
    }

    .onetext-link-modal .onetext-link-modal-alert button {
        background: #444;
        color: #fff;
        border-radius: 4px;
        padding: 5px 20px;
        margin-top: 10px;
    }

    @keyframes fade-in {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes pop-in {
        0% { transform: translate(-50%, -50%) scale(0.1); }
        100% { transform: translate(-50%, -50%) scale(1); }
    }
`;
type LinkModalProps = {
    url : () => Promise<string> | string,
    title : string,
    subTitle ?: string,
    showButton ?: boolean,
    buttonText ?: string,
    showQR ?: boolean,
};

export const LinkModal = ({
    url: getURL,
    title,
    subTitle,
    showButton,
    buttonText,
    showQR
} : LinkModalProps) : JSX.Element | null => {
    const [ visible, setVisible ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ url, setURL ] = useState<string>();

    useEffect(() => {
        setLoading(true);

        void promiseTry(async () => {
            try {
                setURL(await getURL());
            } finally {
                setLoading(false);
            }
        });
    }, []);

    if (!visible) {
        return null;
    }

    const clickOverlay = () : void => {
        setVisible(false);
    };

    const clickAlert = (event : Event) : void => {
        event.stopPropagation();
    };

    const clickButton = () : void => {
        window.open(url, '_blank');
        setVisible(false);
    };

    return (
        <Fragment>
            <style>{ LINK_MODAL_STYLE }</style>
            <div
                className={ 'onetext-link-modal' }
                onClick={ clickOverlay }>
                <div
                    className={ 'onetext-link-modal-alert' }
                    onClick={ clickAlert }>
                    <h1>{ title }</h1>
                    {
                        subTitle
                            ? <h2>{ subTitle }</h2>
                            : null
                    }
                    {
                        loading
                            ? <LoadingDots />
                            : (
                                <Fragment>
                                    {
                                        showQR && url
                                            ? (
                                                <div className={ 'onetext-link-modal-alert-qr' }>
                                                    <QRCode
                                                        url={ url }
                                                        size={ 150 } />
                                                </div>
                                            )
                                            : null
                                    }
                                    {
                                        showButton && buttonText
                                            ? (
                                                <button
                                                    type={ 'button' }
                                                    onClick={ clickButton }>
                                                    { buttonText }
                                                </button>
                                            )
                                            : null
                                    }
                                </Fragment>
                            )
                    }
                </div>
            </div>
        </Fragment>
    );
};
