/** @jsx h */

import { clsx } from 'clsx';
import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';

import { ConciergeContext } from './container';

type BubbleProps = {
    onClick : () => void,
};

export const ConciergeBubble = ({
    onClick
} : BubbleProps) : JSX.Element => {
    const [ isHovered, setIsHovered ] = useState(false);

    const conciergeContext = useContext(ConciergeContext);

    return (
        <div
            style={ { backgroundColor: conciergeContext.brandPrimaryColor } }
            className={
                clsx(
                    'w-[64px] h-[64px]',
                    'rounded-full',
                    'text-white',
                    'flex items-center justify-center',
                    'cursor-pointer',
                    'shadow-lg',
                    'transition-all duration-200',
                    'z-[214748364311]',
                    isHovered && 'transform scale-110'
                )
            }
            onClick={ onClick }
            onMouseEnter={ () => setIsHovered(true) }
            onMouseLeave={ () => setIsHovered(false) }
        >
            <svg
                xmlns={ 'http://www.w3.org/2000/svg' }
                width={ '32' }
                height={ '32' }
                viewBox={ '0 0 24 24' }><path
                    fill={ 'none' }
                    stroke={ 'currentColor' }
                    stroke-linecap={ 'round' }
                    stroke-linejoin={ 'round' }
                    stroke-width={ '2' }
                    d={ 'M8 9h8m-8 4h6m-5 5H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-3l-3 3z' }
                />
            </svg>
        </div>
    );
};
