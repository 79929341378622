import type { ComponentChild, JSX } from 'react';

import React from 'react';

import { clsx } from '../../jsx';

type BasePopupPageProps = {
    children : ComponentChild,
    className ?: string,
    background ?: ComponentChild,
};

export const BasePopupPage = ({
    children,
    className = undefined,
    background
} : BasePopupPageProps) : JSX.Element | null => {
    return (
        <>
            { background }
            <div
                className={
                    clsx(
                        'relative w-full h-full pb-[128px] md:pb-16',
                        className
                    )
                }
            >
                { children }
            </div>
        </>
    );
};
