import type { JSX } from 'react';

import React from 'react';

import type { PopupCommonStyle, PopupConfig } from '@onetext/api';

type BaseLogoProps = {
    style ?: PopupCommonStyle,
    config ?: PopupConfig,
    brandSDKName ?: string,
};

const PLACEHOLDER_LOGO_URL = 'https://static.onetext.com/placeholder/placeholder-logo.webp';

export const BaseLogo = ({
    style,
    config,
    brandSDKName
} : BaseLogoProps) : JSX.Element => {
    const url = config?.component?.logo?.src ?? PLACEHOLDER_LOGO_URL;

    return (
        <div
            className={ 'w-[160px] h-[128px]' }
            style={ style }
        >
            <img
                src={ url }
                alt={ brandSDKName }
                className={ 'object-contain w-full h-full' }
            />
        </div>
    );
};
