/** @jsx h */

import { h } from 'preact';

import { BaseImage } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupImageElement } from '@onetext/api';

type ConsentPopupImageProps = {
    element : PopupImageElement,
};

export const ConsentPopupImage = ({
    element
} : ConsentPopupImageProps) : JSX.Element => {

    const { isDesktopMode, submitPage } = useConsentPopupContext();

    return (
        <BaseImage
            src={ element.src }
            alt={ element.alt }
            style={
                {
                    ...element.style?.mobile,
                    ...isDesktopMode
                        ? element.style?.desktop
                        : {}
                }
            }
            shouldSubmitPage={ element.shouldSubmitPage }
            submitPage={ submitPage }
        />
    );
};
