/** @jsx h */

import { h } from 'preact';

import { BaseLogo } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupLogoProps = {
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupLogo = ({
    overrideStyles
} : ConsentPopupLogoProps) : JSX.Element => {
    const { popup, isDesktopMode } = useConsentPopupContext();

    return (
        <BaseLogo
            brandSDKName={ popup.brandSDKName }
            config={ popup.config }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        />
    );
};
