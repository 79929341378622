/** @jsx h */

import type { FunctionComponent } from 'preact';

import { clsx } from 'clsx';
import { h } from 'preact';

import { useConsentPopupContext } from '../context';

import type { PopupSpinnerConfig } from '@onetext/api';

export enum SPINNER_ANIMATION {
    SPIN_TEASE = 'spin-tease',
    SPINNING = 'spinning',
    SPIN_FINAL = 'spin-final'
}

type ConsentPopupSpinnerProps = {
    animation ?: SPINNER_ANIMATION,
    borderWidth ?: number,
    segmentsLength ?: number,
    options ?: Array<{
        label : string,
    }>,
};

const DEFAULT_OPTIONS = [
    { label: 'Free gift' },
    { label: '10% off' }
];

const defaultColors : PopupSpinnerConfig = {
    arrow: {
        style: {
            background: {
                base:  '#4E6A6C',
                hover: '#4E6A6C'
            }
        }
    },
    hubcap: {
        style: {
            background: {
                base:  '#f2efe8',
                hover: '#f2efe8'
            },
            border: {
                base:  '#ffffff',
                hover: '#ffffff'
            }
        }
    },
    wheel: {
        style: {
            background: {
                base:  '#83bfb5',
                hover: '#83bfb5'
            },
            border: {
                base:  '#83bfb5',
                hover: '#83bfb5'
            }
        }
    },
    freeGift: {
        style: {
            background: {
                base:  '#83bfb5',
                hover: '#83bfb5'
            },
            text: {
                base:  '#ffffff',
                hover: '#ffffff'
            }
        }
    },
    discount: {
        style: {
            background: {
                base:  '#ffffff',
                hover: '#ffffff'
            },
            text: {
                base:  '#000000',
                hover: '#000000'
            }
        }
    }
};

const DEFAULT_BORDER_WIDTH = 12;

const Arrow : FunctionComponent<
    { backgroundColor : string | undefined }
> = ({ backgroundColor }) => (
    <div
        className={
            clsx(
                'absolute w-[22px] h-[51px] top-[-12px] left-1/2 z-[8]',
                '-translate-x-[calc(50%+4px)] md:-translate-x-[calc(50%+6px)]'
            )
        }>
        <div
            data-popup-element={ 'arrow' }
            className={
                'w-[22px] h-[51px] z-10 relative'
            }>
            <svg
                width={ '22' }
                height={ '51' }
                viewBox={ '0 0 22 51' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={
 `
     M0 20.1165
     C0 13 1.28333 -1.09271e-08 11 0
     C20.7167 1.09271e-08 22 13 22 20.1165
     C22 25.6147 17.7862 38.819 14.5747 48.0842
     C13.3691 51.5623 8.63089 51.5623 7.4253 48.0842
     C4.21378 38.819 0 25.6147 0 20.1165Z
 `
                    }
                    fill={ backgroundColor } />
            </svg>
        </div>
        <div
            data-popup-element={ 'arrow-shadow' }
            className={
                clsx(
                    'absolute rounded-full w-3 h-3 left-1/2 top-0.5 z-[6]',
                    '-translate-x-[calc(50%)]',
                    'shadow-[0px_12px_28px_4px_rgba(0,0,0,0.65)]'
                )
            }
        />
    </div>
);

const Hubcap : FunctionComponent<{
    backgroundColor : string | undefined,
    borderColor : string | undefined,
    borderWidth ?: number,
}> = ({
    backgroundColor,
    borderColor,
    borderWidth = DEFAULT_BORDER_WIDTH
}) => (
    <div
        className={
            clsx(
                'rounded-full w-[40%] h-[40%]',
                'absolute z-[4] top-1/2 left-1/2',
            `shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]`
            )
        }
        style={
            {
                backgroundColor,
                borderColor,
                borderWidth,
                transform: `translate(calc(-50% - ${ borderWidth / 2 }px), calc(-50% - ${ borderWidth / 2 }px))`
            }
        }
    />
);

export const ConsentPopupSpinner = ({
    animation = SPINNER_ANIMATION.SPIN_TEASE,
    borderWidth = DEFAULT_BORDER_WIDTH,
    options = DEFAULT_OPTIONS,
    segmentsLength = 8
} : ConsentPopupSpinnerProps) : JSX.Element => {
    const { popup } = useConsentPopupContext();
    const spinnerColors = { ...defaultColors, ...popup.config?.component?.spinner };

    return (
        <div className={
            clsx(
                'relative w-[300px] h-[300px] isolate',
                'md:w-[400px] md:h-[400px]'
            )
        }>
            <Arrow
                backgroundColor={ spinnerColors.arrow?.style?.background?.base } />
            <Hubcap
                backgroundColor={ spinnerColors.hubcap?.style?.background?.base }
                borderColor={ spinnerColors.hubcap?.style?.border?.base }
                borderWidth={ borderWidth }
            />
            <div
                className={
                    clsx(
                        'relative flex flex-col items-center justify-center',
                        'w-[289.5px] h-[289.5px] rounded-full',
                        'z-[3] overflow-hidden shadow-[0px_5px_20px_0px_rgba(51,51,51,0.16)]',
                        'md:w-[386px] md:h-[386px]'
                    )
                }
                style={
                    {
                        backgroundColor: spinnerColors.wheel?.style?.background?.base,
                        borderColor:     spinnerColors.wheel?.style?.border?.base,
                        borderWidth
                    }
                }>
                <div
                    data-popup-element={ 'spinning-wheel' }
                    className={
                        clsx(
                            'relative w-full h-full z-[2] overflow-hidden',
                            {
                                'spin-tease': animation === SPINNER_ANIMATION.SPIN_TEASE,
                                'spinning':   animation === SPINNER_ANIMATION.SPINNING,
                                'spin-final': animation === SPINNER_ANIMATION.SPIN_FINAL
                            }
                        )
                    }>
                    {
                        Array.from({ length: segmentsLength }, (_, index) => {
                            const optionIndex = index % options.length;
                            const isEvenIndex = index % 2 === 0;
                            const currentOption = options[optionIndex];

                            if (!currentOption) {
                                return null;
                            }

                            const angle = (-15) + ((index / segmentsLength) * 360);
                            return (
                                <div
                                    key={ index }
                                    className={
                                        clsx(
                                            'absolute top-0 left-1/2 h-1/2',
                                            'w-[30%]'
                                        )
                                    }
                                    style={
                                        {
                                            zIndex:          index + 1,
                                            backgroundColor: isEvenIndex
                                                ? spinnerColors.freeGift?.style?.background?.base
                                                : spinnerColors.discount?.style?.background?.base,
                                            transform:       `translateX(calc(-50%)) rotate(${ angle }deg)`,
                                            transformOrigin: 'bottom center',
                                            clipPath:        'polygon(100% 0, 0 0, 50% 100%)'
                                        }
                                    }
                                >
                                    <div
                                        className={
                                            clsx(
                                                'relative flex flex-col items-center justify-start w-full h-full',
                                                'z-[1] pt-[5px]',
                                                'md:pt-[26px]'
                                            )
                                        }>
                                        <div
                                            className={
                                                clsx(
                                                    'flex flex-col items-center justify-start max-w-min',
                                                    'w-full relative',
                                                    'font-semibold uppercase text-center',
                                                    'twentieth-century',
                                                    'text-base',
                                                    'font-normal',
                                                    'tracking-[0.4px]',
                                                    'leading-[19.2px]',
                                                    'antialiased',
                                                    isEvenIndex
                                                        ? clsx(
                                                            'md:text-[26px] md:h-[26px] md:leading-[26px]',
                                                            'text-[16px] h-[16px] leading-[16px]',
                                                            'z-[20]'
                                                        )
                                                        : clsx(
                                                            'md:text-[22px] md:h-[44px] md:leading-[22px]',
                                                            'text-[14px] h-[14px] leading-[14px]',
                                                            'z-[15]'
                                                        )
                                                )
                                            }
                                            style={
                                                {
                                                    color: isEvenIndex
                                                        ? spinnerColors.freeGift?.style?.text?.base
                                                        : spinnerColors.discount?.style?.text?.base
                                                }
                                            }>
                                            { currentOption.label }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};
