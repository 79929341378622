/* @jsx h */

import { Component } from 'preact';

import { trackError } from '../lib';

type ErrorBoundaryProps = {
    children : JSX.Element,
};

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
    componentDidCatch(err : unknown) : void {
        trackError(err, {
            type: 'sdk_component_render'
        });
    }

    render() : JSX.Element {
        const { children } = this.props;
        return children;
    }
}

