/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';

import { trackBuyerEvent } from '../../../biz';
import {
    ConsentQuizContext, useConsentFormContext, useConsentPopupContext, useConsentPopupPageContext, useConsentQuizContext
} from '../context';

import { ConsentPopupButton } from './button';
import { ConsentPopupForm } from './form';

import type { PopupQuizElement } from '@onetext/api';
import { BUYER_EVENT } from '@onetext/api';

type ConsentPopupQuizProps = {
    children ?: ComponentChildren,
    propertyName : string,
};

export const ConsentPopupQuiz = ({
    children,
    propertyName
} : ConsentPopupQuizProps) : JSX.Element => {
    const {
        setProperties,
        effectivePopupToken
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentFormContext();

    const submitQuizOption = useCallback(async ({
        propertyValue
    } : {
        propertyValue : string,
    }) => {
        setProperties(existingProperties => {
            return {
                ...existingProperties,
                [ propertyName ]: propertyValue
            };
        });

        trackBuyerEvent({
            buyerEvent: BUYER_EVENT.POPUP_QUIZ_RESULT,
            payload:    {
                popupToken: effectivePopupToken,
                propertyName,
                propertyValue
            }
        });

        await submit();
    }, [ submit, propertyName, setProperties, effectivePopupToken ]);

    const quizContext = useMemo(() => {
        return {
            submitQuizOption
        };
    }, [ submitQuizOption ]);

    return (
        <ConsentQuizContext.Provider value={ quizContext }>
            { children }
        </ConsentQuizContext.Provider>
    );
};

type ConsentPopupQuizOptionProps = {
    propertyValue : string,
    children ?: ComponentChildren,
};

export const ConsentPopupQuizOption = ({
    propertyValue,
    children
} : ConsentPopupQuizOptionProps) : JSX.Element => {
    const {
        submitQuizOption
    } = useConsentQuizContext();

    const {
        openKeyboardOnSubmit
    } = useConsentPopupPageContext();

    const submitQuiz = useCallback(() => {
        return submitQuizOption({
            propertyValue
        });
    }, [ submitQuizOption, propertyValue ]);

    return (
        <ConsentPopupForm
            onSubmit={ submitQuiz }
            openKeyboardOnSubmit={ openKeyboardOnSubmit }
        >
            { children }
        </ConsentPopupForm>
    );
};

export type PopupQuizProps = {
    element : PopupQuizElement,
};

export const ConsentPopupQuizComponent = ({ element } : PopupQuizProps) : JSX.Element => {
    const { isDesktopMode } = useConsentPopupContext();

    return (
        <ConsentPopupQuiz
            propertyName={ element.propertyName }
        >
            <div style={
                {
                    ...element.style?.mobile,
                    ...isDesktopMode
                        ? element.style?.desktop
                        : {}
                }
            }>
                {
                    element.options.map((option) => {
                        return (
                            <ConsentPopupQuizOption
                                key={ option.propertyValue }
                                propertyValue={ option.propertyValue }
                            >
                                <ConsentPopupButton>
                                    { option.content }
                                </ConsentPopupButton>
                            </ConsentPopupQuizOption>
                        );
                    })
                }
            </div>
        </ConsentPopupQuiz>
    );
};
