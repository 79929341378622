/** @jsx h */

import { h } from 'preact';

import { BaseImage } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupImageProps = {
    src ?: string,
    alt ?: string,
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupImage = ({
    src,
    alt,
    overrideStyles
} : ConsentPopupImageProps) : JSX.Element => {

    const { isDesktopMode } = useConsentPopupContext();

    return (
        <BaseImage
            src={ src }
            alt={ alt }
            style={
                isDesktopMode
                    ? overrideStyles?.desktop
                    : overrideStyles?.mobile
            }
        />
    );
};
