export enum SELECTOR {
    KLAVIYO_INPUT = '.klaviyo-form input'
}

export enum DOM_EVENT {
    BLUR = 'blur',
    KEYUP = 'keyup'
}

export enum POPUP_VIEWPORT_MODE {
    MOBILE = 'mobile',
    DESKTOP = 'desktop'
}

export enum MEDIA_QUERY {
    DESKTOP = '(min-width: 1024px)'
}
