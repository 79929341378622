import type { JSX } from 'react';

import React from 'react';

import type { PopupCommonStyle } from '@onetext/api';

type BaseImageProps = {
    style ?: PopupCommonStyle,
    src ?: string,
    alt ?: string,
};

export const BaseImage = ({
    style, src, alt
} : BaseImageProps) : JSX.Element => {

    return (
        <img
            src={ src }
            alt={ alt }
            style={ style }
        />
    );
};
