/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';
import { useState } from 'preact/hooks';

import { delay } from '../../../lib';
import { BaseButton } from '../../base';
import { LoadingDots } from '../../loading-dots';
import { useConsentFormContext, useConsentPopupContext } from '../context';

import type { Milliseconds, PopupResponsiveStyle } from '@onetext/api';
import { BUTTON_LEVEL, BUTTON_SIZE } from '@onetext/api';

type ConsentPopupButtonProps = {
    children : ComponentChildren,
    className ?: string,
    overrideStyles ?: PopupResponsiveStyle,
    closePopup ?: boolean,
    submitDelay ?: Milliseconds,
    level ?: BUTTON_LEVEL,
    size ?: BUTTON_SIZE,
};

export const ConsentPopupButton = ({
    children,
    level = BUTTON_LEVEL.PRIMARY,
    size = BUTTON_SIZE.DEFAULT,
    className = undefined,
    submitDelay,
    closePopup: closePopupOnPress = false,
    overrideStyles
} : ConsentPopupButtonProps) : JSX.Element => {

    const { closePopup, popup, isDesktopMode } = useConsentPopupContext();
    const { submit, attemptSubmit, loading: formLoading } = useConsentFormContext();

    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [ hover, setHover ] = useState(false);
    const [ focus, setFocus ] = useState(false);

    const loading = (
        buttonLoading ||
        formLoading
    );

    const onClick = async (event : Event) : Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (closePopupOnPress) {
            closePopup();
        }

        if (!attemptSubmit()) {
            return;
        }

        setButtonLoading(true);
        setDisabled(true);

        if (submitDelay) {
            await delay(submitDelay);
        }

        await submit();

        setButtonLoading(false);
    };

    const onMouseEnter = () : void => setHover(true);
    const onMouseLeave = () : void => setHover(false);
    const onFocus = () : void => setFocus(true);
    const onBlur = () : void => setFocus(false);

    return (
        <BaseButton
            onClick={ (e) => void onClick(e) }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            onFocus={ onFocus }
            onBlur={ onBlur }
            disabled={ disabled }
            config={ popup.config }
            level={ level }
            size={ size }
            hover={ hover }
            focus={ focus }
            className={ className }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        >
            {
                loading
                    ? <LoadingDots />
                    : children
            }
        </BaseButton>
    );
};
