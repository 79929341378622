import type { ComponentChild, JSX } from 'react';

import React from 'react';

import { clsx } from '../../jsx';

type BasePopupLayoutProps = {
    children : ComponentChild,
    className ?: string,
    style ?: JSX.CSSProperties,
};

const defaultStyle : JSX.CSSProperties = {};

export const BasePopupRow = ({
    children,
    className = '',
    style = defaultStyle
} : BasePopupLayoutProps) : JSX.Element => (
    <div
        className={
            clsx(
                'flex flex-col items-center justify-center h-full',
                className
            )
        }
        style={ style }
    >
        { children }
    </div>
);

export const BasePopupColumns = ({
    children,
    className = '',
    style = defaultStyle,
    childCount
} : BasePopupLayoutProps & {
    childCount : number,
}) : JSX.Element => {
    return (
        <div
            className={
                clsx(
                    'grid h-full gap-16 grid-cols-1 justify-center items-center',
                    childCount > 1
                        ? 'md:grid-cols-[400px_400px]'
                        : 'md:grid-cols-[minmax(0,auto)]',
                    className
                )
            }
            style={ style }
        >
            { children }
        </div>
    );
};

export const BasePopupColumn = ({
    children,
    className = '',
    style = defaultStyle
} : BasePopupLayoutProps) : JSX.Element => (
    <div
        className={
            clsx(
                'flex flex-col items-center justify-center px-2',
                className
            )
        }
        style={ style }
    >
        { children }
    </div>
);
