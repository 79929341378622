/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';

import type { POPUP_VIEWPORT_MODE } from '../../../constants';
import type { ConsentPopupContextType } from '../context';

import {
    getStoredAccountToken, onPopupEmailSubmit, onPopupPhoneSubmit, submitCustomerOptIn, trackBuyerEvent
} from '../../../biz';
import {
    isDevelopment, isEditorMode, isPreviewMode, promiseTry, setLastClosedTime,
    shouldSuppressPopup, shouldSuppressTeaser, track, trackError
} from '../../../lib';
import { Style } from '../../style';
import { ConsentPopupContext } from '../context';
import { useIsDesktopMode } from '../hooks';

import type { COUNTRY, Email, GetPopupAPIResponse, Milliseconds, PhoneNumber, TOKEN, TokenType } from '@onetext/api';
import { BUYER_EVENT, MODAL_TYPE } from '@onetext/api';

type ConsentPopupContainerProps = {
    popup : GetPopupAPIResponse,
    delay ?: Milliseconds,
    suppressedRoutes ?: ReadonlyArray<string>,
    suppressedKeywords ?: ReadonlyArray<string>,
    suppressedQueryParams ?: ReadonlyArray<string>,
    shownToCountries ?: Array<COUNTRY>,
    children ?: ComponentChildren,
    initialProperties ?: Record<string, string>,
    popupTokenOverride ?: TokenType<TOKEN.POPUP>,
    defaultPopupViewportMode ?: POPUP_VIEWPORT_MODE,
};

const DEFAULT_EMPTY_PROPERTIES : Record<string, string> = {};

export const ConsentPopupContainer = ({
    popup,
    delay = 3000 as Milliseconds,
    suppressedRoutes,
    suppressedKeywords,
    suppressedQueryParams,
    shownToCountries,
    children,
    initialProperties = DEFAULT_EMPTY_PROPERTIES,
    popupTokenOverride,
    defaultPopupViewportMode
} : ConsentPopupContainerProps) : JSX.Element => {
    const [ accountToken, setAccountToken ] = useState<TokenType<TOKEN.ACCOUNT> | undefined>(getStoredAccountToken());
    const [ email, setEmail ] = useState<Email>();
    const [ phone, setPhone ] = useState<PhoneNumber>();

    const [ properties, setProperties ] = useState<Record<string, string>>(initialProperties);

    const [ pageIDs, setPageIDs ] = useState<Array<number>>([]);
    const [ activePageID, setActivePageID ] = useState<number>();
    const [ isPopupOpen, setIsPopupOpen ] = useState<boolean>(false);
    const [ isTeaserOpen, setIsTeaserOpen ] = useState<boolean>(true);

    const [ hasSubmittedEmail, setHasSubmittedEmail ] = useState(false);
    const [ hasSubmittedPhone, setHasSubmittedPhone ] = useState(false);

    const [ triggerSubmitData, setTriggerSubmitData ] = useState(false);

    const incrementalPageID = useRef(0);

    const isDesktopMode = useIsDesktopMode({
        defaultPopupViewportMode
    });

    // Temporary override for popup variants
    const effectivePopupToken = useMemo(() => popupTokenOverride ?? popup.token, [ popupTokenOverride, popup.token ]);

    const getNewPageID = () : number => {
        incrementalPageID.current += 1;
        return incrementalPageID.current;
    };

    const registerPage = useCallback((pageID : number) => {
        setActivePageID(existingPageID => {
            return existingPageID ?? pageID;
        });

        setPageIDs(currentPageIDs => {
            return [
                ...currentPageIDs,
                pageID
            ];
        });

        return () => {
            setPageIDs(currentPageIDs => {
                return currentPageIDs.filter(currentPageID => currentPageID !== pageID);
            });
        };
    }, [ activePageID, setActivePageID, setPageIDs ]);

    const submitData = useCallback(() => {
        return promiseTry(async () => {
            if (isEditorMode()) {
                return;
            }

            track('consent_popup_submit', {
                accountToken,
                hasPhone:      Boolean(phone),
                hasEmail:      Boolean(email),
                hasProperties: Boolean(Object.keys(properties).length)
            });

            if (accountToken || email || phone) {
                const isFirstTimeSubmittingEmail = email && !hasSubmittedEmail;
                const isFirstTimeSubmittingPhone = phone && !hasSubmittedPhone;

                if (isFirstTimeSubmittingEmail) {
                    onPopupEmailSubmit.emit({
                        email
                    });

                    setHasSubmittedEmail(true);

                    trackBuyerEvent(
                        {
                            buyerEvent: BUYER_EVENT.POPUP_EMAIL_OPT_IN,
                            payload:    {
                                popupToken: effectivePopupToken,
                                email
                            }
                        }
                    );
                }

                if (isFirstTimeSubmittingPhone) {
                    onPopupPhoneSubmit.emit({
                        phone
                    });

                    setHasSubmittedPhone(true);

                    trackBuyerEvent(
                        {
                            buyerEvent: BUYER_EVENT.POPUP_SMS_OPT_IN,
                            payload:    {
                                popupToken: effectivePopupToken,
                                phone
                            }
                        }
                    );
                }

                const response = await submitCustomerOptIn({
                    accountToken,
                    email,
                    phone,
                    customerProperties:        properties,
                    allowRateLimitWelcomeFlow: !isFirstTimeSubmittingPhone,
                    popupToken:                effectivePopupToken
                });

                if (response.accountToken) {
                    setAccountToken(response.accountToken);
                }
            }
        }).catch(err => {
            trackError(err, {
                type: 'consent_popup_submit'
            });
        });
    }, [
        accountToken,
        email,
        phone,
        properties,
        hasSubmittedEmail,
        hasSubmittedPhone,
        effectivePopupToken
    ]);

    useEffect(() => {
        if (triggerSubmitData) {
            setTriggerSubmitData(false);
            void submitData();
        }
    }, [ submitData, triggerSubmitData ]);

    const advancePage = useCallback(() => {
        if (!activePageID) {
            throw new Error('No active page');
        }

        const currentPageIndex = pageIDs.indexOf(activePageID);

        if (currentPageIndex === -1) {
            throw new Error('Can not find current page');
        }

        const nextPageIndex = currentPageIndex + 1;

        if (nextPageIndex >= pageIDs.length) {
            setIsPopupOpen(false);
            return;
        }

        setActivePageID(pageIDs[nextPageIndex]);
    }, [ activePageID, pageIDs, setActivePageID, setIsPopupOpen ]);

    const submitPage = useCallback(() => {
        return promiseTry(() => {
            setTriggerSubmitData(true);
            advancePage();
        });
    }, [
        activePageID,
        accountToken,
        email,
        phone,
        properties,
        advancePage,
        hasSubmittedEmail,
        hasSubmittedPhone
    ]);

    const openPopup = useCallback(() => {
        setIsPopupOpen(true);
        setIsTeaserOpen(false);
    }, [ setIsPopupOpen, setIsTeaserOpen ]);

    const closePopup = useCallback(() => {
        setIsPopupOpen(false);
        setIsTeaserOpen(true);

        setLastClosedTime({ token: effectivePopupToken, type: MODAL_TYPE.POPUP });

        incrementalPageID.current = 0;
    }, [ effectivePopupToken, setIsPopupOpen, setIsTeaserOpen ]);

    const closeTeaser = useCallback(() => {
        setIsTeaserOpen(false);

        setLastClosedTime({ token: effectivePopupToken, type: MODAL_TYPE.TEASER });
    }, [ effectivePopupToken, setIsTeaserOpen ]);

    const suppressTeaser = useMemo(() => {
    // Temporary override for popup variants
        return shouldSuppressTeaser({
            popup: {
                ...popup,
                token: effectivePopupToken
            },
            suppressedRoutes,
            suppressedKeywords,
            suppressedQueryParams,
            shownToCountries
        });
    }, [ popup, suppressedRoutes, suppressedKeywords, suppressedQueryParams, shownToCountries ]);

    const suppressPopup = useMemo(() => {
    // Temporary override for popup variants
        return shouldSuppressPopup({
            popup: {
                ...popup,
                token: effectivePopupToken
            },
            suppressedRoutes,
            suppressedKeywords,
            suppressedQueryParams,
            shownToCountries
        });
    }, [ popup, suppressedRoutes, suppressedKeywords, suppressedQueryParams, shownToCountries ]);

    const popupContext : ConsentPopupContextType = useMemo(() => {
        return {
            popup,
            accountToken,
            email,
            setEmail,
            phone,
            setPhone,
            properties,
            setProperties,
            registerPage,
            activePageID,
            submitPage,
            isPopupOpen,
            setIsPopupOpen,
            isTeaserOpen,
            setIsTeaserOpen,
            openPopup,
            closePopup,
            closeTeaser,
            suppressedRoutes,
            getNewPageID,
            suppressTeaser,
            suppressPopup,
            effectivePopupToken,
            isDesktopMode
        };
    }, [
        popup,
        accountToken,
        email,
        setEmail,
        phone,
        setPhone,
        properties,
        setProperties,
        registerPage,
        activePageID,
        submitPage,
        isPopupOpen,
        setIsPopupOpen,
        isTeaserOpen,
        setIsTeaserOpen,
        openPopup,
        closePopup,
        closeTeaser,
        suppressedRoutes,
        getNewPageID,
        suppressTeaser,
        suppressPopup,
        effectivePopupToken,
        isDesktopMode
    ]);

    useEffect(() : void => {
        if (suppressPopup.suppressed) {
            return;
        }

        if (delay && !isDevelopment() && !isPreviewMode() && !isEditorMode()) {
            setTimeout(() => openPopup(), delay);
        } else {
            openPopup();
        }
    }, [ suppressPopup.suppressed, openPopup, delay ]);

    return (
        <ConsentPopupContext.Provider value={ popupContext }>
            <Style>
                { children }
            </Style>
        </ConsentPopupContext.Provider>
    );
};
