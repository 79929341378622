/** @jsx h */

import { h } from 'preact';

import { BaseBackground } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';
import { TEMPLATE_CANVAS_TYPE } from '@onetext/api';

type ConsentPopupBackgroundProps = {
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupBackground = ({ overrideStyles } : ConsentPopupBackgroundProps) : JSX.Element | null => {
    const { popup, isDesktopMode } = useConsentPopupContext();

    const backgroundImageSrc = popup.config?.template?.background?.type &&
        popup.config.template.background.type === TEMPLATE_CANVAS_TYPE.SINGLE_IMAGE
        ? popup.config.template.background.src
        : undefined;

    if (!backgroundImageSrc) {
        return null;
    }

    return (
        <BaseBackground
            config={ popup.config }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        />
    );
};
