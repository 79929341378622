import { trackBuyerEvent } from '../biz';

import { isDevelopment, isEditorMode } from './sdk';

import type { HTTPUrl, TOKEN, TokenType } from '@onetext/api';
import { BUYER_EVENT } from '@onetext/api';

export type TrackWebsiteViewParams = {
    popupToken ?: TokenType<TOKEN.POPUP>,
};

type WebsiteViewPayload = {
    url : HTTPUrl,
    popupToken ?: TokenType<TOKEN.POPUP>,
};

export const trackWebsiteView = (params : TrackWebsiteViewParams = {}) : void => {
    if (isDevelopment() || isEditorMode()) {
        return;
    }

    const payload : WebsiteViewPayload = {
        url:        window.location.href as HTTPUrl,
        popupToken: params.popupToken
    };

    trackBuyerEvent({
        buyerEvent: BUYER_EVENT.PAGE_VIEW,
        payload
    });
};

// Raycon popup token for standard template
export const RAYCON_POPUP_TOKEN =
    'onetext_popup_production_db17f81d-1f23-41d5-8f53-21ec031de1ed' as TokenType<TOKEN.POPUP>;

export const isRayconPopup = (token : TokenType<TOKEN.POPUP>) : boolean => {
    return token === RAYCON_POPUP_TOKEN;
};
