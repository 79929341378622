/** @jsx h */

import { h } from 'preact';
import QRC from 'qrcode-svg';

type QRCodeProps = {
    url : string,
    size : number,
    color ?: string,
    background ?: string,
};

export const QRCode = ({
    url,
    size,
    color = '#000000',
    background = '#ffffff'
} : QRCodeProps) : JSX.Element => {
    return (
        <a
            href={ url }
            ref={
                element => {
                    if (element) {
                        const qr = new QRC({
                            content: url,
                            padding: 0,
                            width:   size,
                            height:  size,
                            color,
                            background
                        });
                        element.innerHTML = qr.svg();
                    }
                }
            } />
    );
};
