import type { GetPopupAPIResponse } from '@onetext/api';

type PopupProps = {
    popup : GetPopupAPIResponse,
};

type PopupComponent = (props : PopupProps) => JSX.Element | null;

type RegisterPopupConfig = {
    externalID : string,
    Component : PopupComponent,
};

const popupRegistry = new Map<string, PopupComponent>();

type RegistrationCallback = (externalID : string) => void;

const registrationCallbacks : Array<RegistrationCallback> = [];

export const onPopupRegistration = (callback : RegistrationCallback) : void => {
    registrationCallbacks.push(callback);
};

export const registerPopup = (config : RegisterPopupConfig) : void => {
    popupRegistry.set(config.externalID, config.Component);

    registrationCallbacks.forEach(callback => {
        callback(config.externalID);
    });
};

export const getPopupComponent = (externalID : string) : PopupComponent | undefined => {
    return popupRegistry.get(externalID);
};
