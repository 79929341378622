/** @jsx h */

import { h } from 'preact';

import { BaseBackground } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupBackgroundProps = {
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupBackground = ({ overrideStyles } : ConsentPopupBackgroundProps) : JSX.Element | null => {
    const { popup, isDesktopMode } = useConsentPopupContext();

    return (
        <BaseBackground
            config={ popup.config }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        />
    );
};
