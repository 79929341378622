import type { JSX } from 'react';

import React from 'react';

import { DEFAULT_BUTTON_CONFIG } from '../../constants';
import { clsx } from '../../jsx';

import type { PopupCommonStyle, PopupConfig } from '@onetext/api';
import { BUTTON_LEVEL, BUTTON_SIZE, COMPONENT_STATE, STYLE_ATTRIBUTE } from '@onetext/api';

type BaseButtonProps = {
    config ?: PopupConfig,
    style ?: PopupCommonStyle,
    level ?: BUTTON_LEVEL,
    size ?: BUTTON_SIZE,
    hover ?: boolean,
    focus ?: boolean,
} & Omit<JSX.IntrinsicElements['button'], 'size' | 'style'>;

export const BaseButton = (
    {
        style, children, className, config,
        level = BUTTON_LEVEL.PRIMARY,
        size = BUTTON_SIZE.MEDIUM,
        hover,
        focus,
        ...props
    } : BaseButtonProps
) : JSX.Element => {
    const getColorValue = (
        buttonLevel : BUTTON_LEVEL,
        attribute : STYLE_ATTRIBUTE,
        state : COMPONENT_STATE
    ) : string => {
        const buttonConfig = config?.component?.button ?? DEFAULT_BUTTON_CONFIG;

        return buttonConfig.level?.[buttonLevel]?.style?.[attribute]?.[state] ??
            DEFAULT_BUTTON_CONFIG.level?.[buttonLevel]?.style?.[attribute]?.[state] ??
            '';
    };

    const getLevelStyle = (
        buttonLevel : BUTTON_LEVEL
    ) : { [key : string] : string } => {
        const baseBackgroundColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.BACKGROUND, COMPONENT_STATE.BASE);
        const hoverBackgroundColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.BACKGROUND, COMPONENT_STATE.HOVER);
        const baseTextColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.TEXT, COMPONENT_STATE.BASE);
        const hoverTextColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.TEXT, COMPONENT_STATE.HOVER);
        const baseBorderColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.BORDER, COMPONENT_STATE.BASE);
        const hoverBorderColor = getColorValue(buttonLevel, STYLE_ATTRIBUTE.BORDER, COMPONENT_STATE.HOVER);
        return {
            color: hover || focus
                ? hoverTextColor
                : baseTextColor,
            background: hover || focus
                ? hoverBackgroundColor
                : baseBackgroundColor,
            borderColor: hover || focus
                ? hoverBorderColor
                : baseBorderColor
        };
    };

    const getSizeStyle = (
        buttonSize : BUTTON_SIZE
    ) : { [key : string] : string } => {
        return config?.component?.button?.size?.[buttonSize]?.style ??
            DEFAULT_BUTTON_CONFIG.size?.[buttonSize]?.style ?? {};
    };

    const { borderColor, ...levelStyle } = getLevelStyle(level);
    const sizeStyle = getSizeStyle(size);

    return (
        <button
            type={ 'button' }
            className={
                clsx(
                    'disabled:cursor-wait disabled:opacity-50 focus:outline-none',
                    'h-12 cursor-pointer w-full p-2 min-w-[200px] max-w-[384px] text-center outline-none',
                    className
                )
            }
            style={
                {
                    ...borderColor
                        ? { border: `1px solid ${ borderColor }` }
                        : {},
                    ...levelStyle,
                    ...sizeStyle,
                    ...style
                }
            }
            { ...props }
        >
            { children }
        </button>
    );
};

