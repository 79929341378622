/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';

type StyleProps = {
    children : ComponentChildren,
};

export const Style = ({ children } : StyleProps) : JSX.Element | null => {
    return (
        <div className={ 'ot-sdk' }>
            { children }
        </div>
    );
};
