export enum SDK_ATTRIBUTE {
    ENV = 'data-env',
    ACCOUNT_TOKEN = 'data-account-token',
    CLIENT_ID = 'data-client-id',
    API_DOMAIN = 'data-api-domain',
    POPUP_ID = 'data-popup-id',
    EDITOR = 'data-editor'
}

export enum SDK_QUERY {
    ACCOUNT_TOKEN = 'onetext-account-token',
    CLIENT_ID = 'onetext-client',
    POPUP_ID = 'popup-id',
    EDITOR = 'onetext-editor'
}

export enum QUERY_PARAM {
    ONETEXT_ACCOUNT_TOKEN = 'onetext-account-token',
    KLAVIYO_IDENTIFIER = 'nb_klid',
    PREVIEW = 'onetext-preview',
    POPUP_ID = 'popup-id',
    CONCIERGE_PREVIEW = 'ot-concierge-preview'
}

export enum LOCALSTORAGE_KEY {
    ONETEXT_ACCOUNT_TOKEN = 'onetext-account-token',
    BROWSER_IDENTIFIER = 'onetext-browser-id'
}

export enum SESSIONSTORAGE_KEY {
    BROWSER_SESSION_IDENTIFIER = 'onetext-browser-session-id'
}

export enum BRAND_SDK_ATTRIBUTE {
    POPUP_ID = 'data-popup-id'
}

export enum BRAND_SDK_QUERY {
    POPUP_ID = 'popup-id'
}
