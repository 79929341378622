import { v4 as uuid } from 'uuid';

import { associate } from '../../api';
import { LOCALSTORAGE_KEY, QUERY_PARAM, SESSIONSTORAGE_KEY } from '../../constants';
import { userIdentifiedEvent } from '../../event';
import {
    debouncePromise, isLocalStorageEnabled, localStorageGet, localStorageSet, promiseTry,
    sessionStorageGet, sessionStorageSet
} from '../../lib';

import type { Email, PhoneNumber, TOKEN, TokenType } from '@onetext/api';

export const getStoredAccountToken = () : TokenType<TOKEN.ACCOUNT> | undefined => {
    const queryString = new URLSearchParams(window.location.search);

    const accountToken = (
        queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN) ??
        queryString.get(QUERY_PARAM.ONETEXT_ACCOUNT_TOKEN_LEGACY) ??
        localStorageGet(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN)
    ) ?? undefined;

    return accountToken as TokenType<TOKEN.ACCOUNT> | undefined;
};

export const isCustomerRecognized = () : boolean => {
    return Boolean(getStoredAccountToken());
};

export const storeAccountLocally = (accountToken : string) : void => {
    const localStorageEnabled = isLocalStorageEnabled();

    if (accountToken && localStorageEnabled) {
        localStorageSet(LOCALSTORAGE_KEY.ONETEXT_ACCOUNT_TOKEN, accountToken);
    }
};

type Customer = {
    recognized : boolean,
};

export const getCustomer = () : Promise<Customer> => {
    return promiseTry(() => {
        const recognized = isCustomerRecognized();

        return {
            recognized
        };
    });
};

export const getStoredBrowserIdentifier = () : string => {
    const identifier = localStorageGet(LOCALSTORAGE_KEY.BROWSER_IDENTIFIER);

    if (identifier) {
        return identifier;
    }

    const browserIdentifier = uuid();

    localStorageSet(LOCALSTORAGE_KEY.BROWSER_IDENTIFIER, browserIdentifier);

    return browserIdentifier;
};

export const getStoredBrowserSessionIdentifier = () : string => {
    const identifier = sessionStorageGet(SESSIONSTORAGE_KEY.BROWSER_SESSION_IDENTIFIER);

    if (identifier) {
        return identifier;
    }

    const browserSessionIdentifier = uuid();

    sessionStorageSet(
        SESSIONSTORAGE_KEY.BROWSER_SESSION_IDENTIFIER,
        browserSessionIdentifier
    );

    return browserSessionIdentifier;
};

type CustomerIdentity = {
    klaviyoID ?: string,
    accountToken ?: TokenType<TOKEN.ACCOUNT>,
    phone ?: PhoneNumber,
    email ?: Email,
    cartToken ?: string,
    customerBrowserIdentifier ?: string,
    customerBrowserSessionIdentifier ?: string,
};

const identifierState : CustomerIdentity = {
    customerBrowserIdentifier:        getStoredBrowserIdentifier(),
    customerBrowserSessionIdentifier: getStoredBrowserSessionIdentifier()
};

export const associateCustomer = debouncePromise(async () => {
    const {
        body: {
            accountToken
        }
    } = await associate(identifierState);

    if (accountToken) {
        identifierState.accountToken = accountToken;
        storeAccountLocally(accountToken);
        userIdentifiedEvent.emit({ accountToken });
    }

    return {
        accountToken
    };
}, 1000);

type IdentifyCustomerResponse = {
    accountToken : TokenType<TOKEN.ACCOUNT> | undefined,
};

export const identifyCustomer = ({
    klaviyoID,
    accountToken,
    phone,
    email,
    cartToken,
    customerBrowserIdentifier,
    customerBrowserSessionIdentifier
} : CustomerIdentity) : Promise<IdentifyCustomerResponse> => {
    return promiseTry(() => {
        let newIdentifiers = false;

        if (accountToken && accountToken !== identifierState.accountToken) {
            identifierState.accountToken = accountToken;
            newIdentifiers = true;
        }

        if (klaviyoID && klaviyoID !== identifierState.klaviyoID) {
            identifierState.klaviyoID = klaviyoID;
            newIdentifiers = true;
        }

        if (phone && phone !== identifierState.phone) {
            identifierState.phone = phone;
            newIdentifiers = true;
        }

        if (email && email !== identifierState.email) {
            identifierState.email = email;
            newIdentifiers = true;
        }

        if (cartToken && cartToken !== identifierState.cartToken) {
            identifierState.cartToken = cartToken;
            newIdentifiers = true;
        }

        if (
            customerBrowserIdentifier &&
            customerBrowserIdentifier !== identifierState.customerBrowserIdentifier
        ) {
            identifierState.customerBrowserIdentifier = customerBrowserIdentifier;
            newIdentifiers = true;
        }

        if (
            customerBrowserSessionIdentifier &&
            customerBrowserSessionIdentifier !==
            identifierState.customerBrowserSessionIdentifier
        ) {
            identifierState.customerBrowserSessionIdentifier =
        customerBrowserSessionIdentifier;

            newIdentifiers = true;
        }

        if (newIdentifiers) {
            return associateCustomer();
        } else {
            return {
                accountToken: getStoredAccountToken()
            };
        }
    });
};

export const initializeCustomer = async () : Promise<void> => {
    const accountToken = getStoredAccountToken();

    if (accountToken) {
        await identifyCustomer({
            accountToken,
            customerBrowserIdentifier:        getStoredBrowserIdentifier(),
            customerBrowserSessionIdentifier: getStoredBrowserSessionIdentifier()
        });
    }
};
