import type { JSX } from 'react';

import React from 'react';

import type { PopupCommonStyle } from '@onetext/api';

type BaseTextProps = {
    style ?: PopupCommonStyle,
    text : string | undefined,
};

export const BaseText = ({
    style,
    text
} : BaseTextProps) : JSX.Element => {
    return (
        <div
            className={
                'w-full font-medium text-sm leading-[1.5] text-center'
            }
            style={ style }
        >
            { text }
        </div>
    );
};
