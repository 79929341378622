/** @jsx h */

import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';

import type { TeaserTextRenderFunction } from '../../../types';

import { trackBuyerEvent } from '../../../biz';
import { removeLastClosedTime, track } from '../../../lib';
import { BasePopupTeaser } from '../../base';
import { useConsentPopupContext } from '../context';

import { CloseButton } from './close-button';

import { BUYER_EVENT, CLOSE_BUTTON_POSITION, CLOSE_BUTTON_SIZE, MODAL_TYPE, POSITION } from '@onetext/api';

type ConsentPopupTeaserProps = {
    className ?: string,
    customText ?: string | TeaserTextRenderFunction,
    closeButtonPosition ?: CLOSE_BUTTON_POSITION,
};

export const ConsentPopupTeaser = ({
    className,
    customText,
    closeButtonPosition = CLOSE_BUTTON_POSITION.RIGHT
} : ConsentPopupTeaserProps) : JSX.Element | null => {
    const {
        popup,
        openPopup,
        isTeaserOpen,
        accountToken,
        suppressTeaser,
        properties,
        effectivePopupToken
    } = useConsentPopupContext();

    const [ hover, setHover ] = useState(false);

    useEffect(() => {
        track('consent_teaser_render', { accountToken });
    }, []);

    useEffect(() => {
        track('consent_teaser_init', {
            accountToken
        });

        if (suppressTeaser.suppressed) {
            track('consent_teaser_suppress', {
                reason: suppressTeaser.reason
            });
        } else {
            track('consent_teaser_render', {
                accountToken
            });

            trackBuyerEvent({
                buyerEvent: BUYER_EVENT.POPUP_TEASER_RENDER,
                payload:    {
                    popupToken: effectivePopupToken
                }
            });
        }
    }, [ suppressTeaser.suppressed ]);

    const handleClick = useCallback(() : void => {
        removeLastClosedTime({ token: effectivePopupToken, type: MODAL_TYPE.POPUP });
        openPopup();
    }, [ effectivePopupToken, openPopup ]);

    return (
        <BasePopupTeaser
            onClick={ handleClick }
            onMouseEnter={ () => setHover(true) }
            onMouseLeave={ () => setHover(false) }
            className={ className }
            aria-label={ 'View offer' }
            closeButton={
                <CloseButton
                    key={ 'close' }
                    overrideStyles={
                        {
                            mobile: {
                                position: POSITION.RELATIVE,
                                top:      'auto',
                                left:     'auto',
                                right:    'auto'
                            }
                        }
                    }
                    size={ CLOSE_BUTTON_SIZE.SM }
                    animate={ false }
                    type={ MODAL_TYPE.TEASER }
                />
            }
            config={ popup.config }
            isTeaserOpen={ isTeaserOpen }
            isTeaserSuppressed={ suppressTeaser.suppressed }
            properties={ properties }
            closeButtonPosition={ closeButtonPosition }
            hover={ hover }
            customText={ customText }
        />
    );
};
