import type { Cancelable } from '../types';

import { getURLQuery } from './dom';

import type { Email, HTTPQuery, HTTPQueryInput, HTTPUrl, Milliseconds, PhoneNumber } from '@onetext/api';
import { ENV, LOCAL_PHONE_AREA_CODE } from '@onetext/api';

const envs = Object.values(ENV);

// eslint-disable-next-line security/detect-non-literal-regexp
const tokenRegex = new RegExp(`^onetext(?:_\\w+)+_(${ envs.join('|') })_`);

export const tokenToEnv = (token : string) : ENV => {
    const match = token.match(tokenRegex);

    if (!match) {
        throw new Error(`Can not determine SDK environment`);
    }

    const env = match[1];

    if (!env) {
        throw new Error(`Can not determine SDK environment`);
    }

    if (!envs.includes(env)) {
        throw new Error(`Invalid SDK environment: ${ env }`);
    }

    return env as ENV;
};

export const run = <Type>(handler : () => Type) : Type => {
    return handler();
};

export const promiseTry = async <Type>(handler : () => Promise<Type> | Type) : Promise<Type> => {
    return await handler();
};

let localStorageEnabled : boolean | undefined;

export const isLocalStorageEnabled = () : boolean => {
    if (localStorageEnabled !== undefined) {
        return localStorageEnabled;
    }

    try {
        if (typeof window === 'undefined') {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (window.localStorage) {
            const value = Math.random().toString();
            window.localStorage.setItem('__test__localStorage__', value);
            const result = window.localStorage.getItem('__test__localStorage__');
            window.localStorage.removeItem('__test__localStorage__');

            if (value === result) {
                localStorageEnabled = true;
                return true;
            }
        }
    } catch {
        // pass
    }

    localStorageEnabled = false;
    return false;
};

const memoryLocalStorage : {
    [ key : string ] : string | undefined,
} = {};

export const localStorageSet = (key : string, value : string) : string => {
    memoryLocalStorage[key] = value;

    if (!isLocalStorageEnabled()) {
        return value;
    }

    try {
        window.localStorage.setItem(key, value);
    } catch {
        // pass
    }

    return value;
};

export const localStorageGet = (key : string) : string | undefined => {
    if (key in memoryLocalStorage) {
        return memoryLocalStorage[key];
    }

    if (!isLocalStorageEnabled()) {
        return;
    }

    try {
        return window.localStorage.getItem(key) ?? undefined;
    } catch {
        // pass
    }
};

export const localStorageRemove = (key : string) : void => {
    delete memoryLocalStorage[key];

    if (!isLocalStorageEnabled()) {
        return;
    }

    try {
        return window.localStorage.removeItem(key);
    } catch {
        // pass
    }
};

export const getUserAgent = () : string | undefined => {
    try {
        return window.navigator.mockUserAgent ?? window.navigator.userAgent;
    } catch {
        // pass
    }
};

export const isDevice = (userAgent : string | undefined = getUserAgent()) : boolean => {
    if (!userAgent) {
        return false;
    }

    if ((/android|webos|iphone|ipad|ipod|bada|symbian|palm|crios|blackberry|iemobile|windowsmobile|opera mini/i).test(userAgent)) {
        return true;
    }

    return false;
};

export const noop = () : void => {
    // pass
};

export const assertExists = <T>(thing : undefined | null | T) : T => {
    if (thing === null || thing === undefined) {
        throw new Error(`Expected value to be present`);
    }

    return thing;
};

export const getStackTrace = () : string => {
    try {
        throw new Error('_');
    } catch (err) {
        return (err as Error).stack ?? '';
    }
};

const inferCurrentScript = () : HTMLScriptElement | undefined => {
    try {
        const stack = getStackTrace();
        const stackDetails = (/.*at [^(]*\((.*):(.+):(.+)\)$/gi).exec(stack);
        const scriptLocation = stackDetails?.[1];

        if (!scriptLocation) {
            return;
        }

        for (const element of Array.prototype.slice.call(document.getElementsByTagName('script')).reverse()) {
            const script = element as HTMLScriptElement;

            if (script.src && script.src === scriptLocation) {
                return script;
            }
        }
    } catch {
        // pass
    }
};

let currentScript : HTMLScriptElement | undefined = typeof document === 'undefined'
    ? undefined
    : document.currentScript as HTMLScriptElement | undefined;

type GetCurrentScript = () => HTMLScriptElement;

export const getCurrentScript : GetCurrentScript = () => {
    if (currentScript) {
        return currentScript;
    }

    currentScript = inferCurrentScript();

    if (currentScript) {
        return currentScript;
    }

    throw new Error('Can not determine current script');
};

export const getCurrentScriptURL = () : string => {
    return getCurrentScript().src;
};

export const getCurrentScriptBasePath = () : string => {
    return getCurrentScriptURL().replace(/[^/]*$/, '');
};

export const getBody = () : HTMLBodyElement => {
    const body = document.body as HTMLBodyElement | undefined;

    if (!body) {
        throw new Error(`Body element not found`);
    }

    return body;
};

export const awaitBody = async () : Promise<HTMLBodyElement> => {
    const existingBody = document.body as HTMLBodyElement | undefined;

    if (existingBody) {
        return existingBody;
    }

    return new Promise<HTMLBodyElement>(resolve => {
        const check = () : void => {
            const body = document.body as HTMLBodyElement | undefined;

            if (body) {
                resolve(body);
            }
        };

        window.addEventListener('load', check);
        document.addEventListener('DOMContentLoaded', check);
    });
};

export const debounce = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FunctionType extends (...args : Array<any>) => any
>(
    func : FunctionType,
    delay : number
) : ((...args : Parameters<FunctionType>) => void) => {
    let timeoutId : ReturnType<typeof setTimeout>;

    const wrapper = (...args : ReadonlyArray<unknown>) : void => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };

    return wrapper;
};

export const debouncePromise = <
    FunctionReturnType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FunctionType extends (...args : Array<any>) => Promise<FunctionReturnType>,
    FinalReturnType extends Awaited<ReturnType<FunctionType>>
>(
    func : FunctionType,
    delay : number
) : ((...args : Parameters<FunctionType>) => Promise<FinalReturnType>) => {
    let timer : ReturnType<typeof setTimeout>;

    let promise : Promise<FinalReturnType> | undefined;
    let resolve : ((value : FinalReturnType) => void) | undefined;
    let reject : ((err : unknown) => void) | undefined;

    const wrapper = (...args : ReadonlyArray<unknown>) : Promise<FinalReturnType> => {
        clearTimeout(timer);

        // eslint-disable-next-line promise/param-names
        promise ??= new Promise<FinalReturnType>((res, rej) => {
            resolve = res;
            reject = rej;
        });

        const innerResolve = resolve;
        const innerReject = reject;

        if (!innerResolve || !innerReject) {
            throw new Error('Promise not initialized');
        }

        timer = setTimeout(() => {
            void promiseTry(async () => {
                let result;

                try {
                    result = await func(...args);
                    innerResolve(result as unknown as FinalReturnType);
                } catch (err) {
                    innerReject(err);
                } finally {
                    promise = undefined;
                    resolve = undefined;
                    reject = undefined;
                }
            });
        }, delay);

        return promise;
    };

    return wrapper;
};

type Eventable = {
    addEventListener : (type : string, handler : (event : Event) => void) => void,
    removeEventListener : (type : string, handler : (event : Event) => void) => void,
};

export const listen = (
    item : Eventable,
    eventName : string,
    handler : (event : Event) => void
) : Cancelable => {
    item.addEventListener(eventName, handler);

    return {
        cancel: () => {
            item.removeEventListener(eventName, handler);
        }
    };
};

type MemoizeOptions = {
    ttl ?: number,
};

export const memoize = <
    FunctionType extends (...args : Array<unknown>) => unknown
>(
    func : FunctionType,
    opts : MemoizeOptions = {}
) : ((...args : Parameters<FunctionType>) => ReturnType<FunctionType>) => {
    const {
        ttl = 5 * 60 * 1000
    } = opts;

    let result : {
        value : ReturnType<FunctionType>,
        timestamp : number,
    } | undefined;

    return (...args : Parameters<FunctionType>) => {
        const now = Date.now();

        if (result && now - result.timestamp < ttl) {
            return result.value;
        }

        result = {
            value:     func(...args) as ReturnType<FunctionType>,
            timestamp: now
        };

        return result.value;
    };
};

export const assertUnreachable = (value : never) : Error => {
    throw new Error(`Unreachable value: ${ JSON.stringify(value) }`);
};

export type ExpandedPromise<Type> = {
    promise : Promise<Type>,
    resolve : (value : Type) => void,
    reject : (error : unknown) => void,
};

export const createPromise = <Type>() : ExpandedPromise<Type> => {
    let promiseResolve;
    let promiseReject;

    const promise = new Promise<Type>((resolve, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!promiseResolve || !promiseReject) {
        throw new Error('Promise not initialized');
    }

    return {
        promise,
        resolve: promiseResolve,
        reject:  promiseReject
    };
};

export const isValidEmail = (email : string) : email is Email => {
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)) {
        return false;
    }

    return true;
};

export const removePhoneCountryCode = (phone : PhoneNumber) : string => {
    return phone.replace(/^\+1/, '');
};

const localPhoneAreaCodes = Object.values(LOCAL_PHONE_AREA_CODE);

type IsValidPhoneNumberOptions = {
    validateAreaCode ?: boolean,
};

export const isValidPhoneNumber = (phone : string, {
    validateAreaCode = true
} : IsValidPhoneNumberOptions = {}) : phone is PhoneNumber => {
    if (!(/^\+1\d{10}$/).test(phone)) {
        return false;
    }

    const phoneWithoutCountryCode = phone.replace(/^\+1/, '');

    if (
        phoneWithoutCountryCode.startsWith('0') ||
        phoneWithoutCountryCode.startsWith('1')
    ) {
        return false;
    }

    if (
        phoneWithoutCountryCode.slice(3, 4) === '0' ||
        phoneWithoutCountryCode.slice(3, 4) === '1'
    ) {
        return false;
    }

    if (validateAreaCode) {
        const areaCode = phoneWithoutCountryCode.slice(0, 3);

        if (!localPhoneAreaCodes.includes(areaCode)) {
            return false;
        }
    }

    return true;
};

type ParsePhoneOptions = {
    validateAreaCode ?: boolean,
};

export const parsePhone = (potentialPhone : string, {
    validateAreaCode = true
} : ParsePhoneOptions = {}) : PhoneNumber | undefined => {
    const normalizedPhone = potentialPhone.replace(/\D+/g, '');

    if (isValidPhoneNumber(normalizedPhone, { validateAreaCode })) {
        return normalizedPhone;
    }

    const normalizedPhoneWithPlus = `+${ normalizedPhone }`;

    if (isValidPhoneNumber(normalizedPhoneWithPlus, { validateAreaCode })) {
        return normalizedPhoneWithPlus;
    }

    const normalizedPhoneWithPlusOne = `+1${ normalizedPhone }`;

    if (isValidPhoneNumber(normalizedPhoneWithPlusOne, { validateAreaCode })) {
        return normalizedPhoneWithPlusOne;
    }
};

export const identity = <Type>(value : Type) : Type => {
    return value;
};

export const randomInteger = (min : number, max : number) : number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const delay = (ms : Milliseconds) : Promise<void> => new Promise(resolve => {
    setTimeout(resolve, ms);
});

export const isIncompatibleBrowser = () : boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!Symbol.iterator) {
        return true;
    }

    const arr : ReadonlyArray<never> = [];

    if (!arr[Symbol.iterator]) {
        return true;
    }

    if (typeof window.scrollTo !== 'function') {
        return true;
    }

    if (typeof Object.fromEntries !== 'function') {
        return true;
    }

    try {
        getURLQuery();
    } catch {
        return true;
    }

    return false;
};

export const urlEncode = (str : string) : string => encodeURIComponent(str);

export const formatQuery = (obj : HTTPQueryInput = {}) : string => Object.keys(obj).filter(key => {
    return typeof obj[key] === 'string' || typeof obj[key] === 'boolean' || typeof obj[key] === 'number';
}).map(key => {
    const val = obj[key];

    if (typeof val !== 'string' && typeof val !== 'boolean' && typeof val !== 'number') {
        throw new TypeError(`Invalid type for query`);
    }

    return `${ urlEncode(key) }=${ urlEncode(val.toString()) }`;
}).join('&');

export const parseQuery = (queryString : string) : HTTPQuery => {
    const params : HTTPQuery = {};

    if (!queryString) {
        return params;
    }

    if (queryString.indexOf('=') === -1) {
        return params;
    }

    for (const pair of queryString.split('&')) {
        const [ key, value ] = pair.split('=');

        if (key && value) {
            params[decodeURIComponent(key)] = decodeURIComponent(value);
        }
    }

    return params;
};

export const extendQuery = (originalQuery : string, props : HTTPQueryInput = {}) : string => {

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!props || !Object.keys(props).length) {
        return originalQuery;
    }

    const queryPrefix = originalQuery.match(/^([^=]+)(&|$)/)?.[1] ?? '';

    return (queryPrefix
        ? `${ queryPrefix }&`
        : ``) + formatQuery({
        ...parseQuery(originalQuery.slice(queryPrefix.length)),
        ...props
    });
};

export const extendUrl = (url : string, options : { query ?: HTTPQueryInput, hash ?: HTTPQueryInput }) : HTTPUrl => {

    const query = options.query ?? {};
    const hash = options.hash ?? {};

    let originalUrl;
    let originalQuery;
    let originalHash;

    [ originalUrl = '', originalHash = '' ] = url.split('#');
    [ originalUrl = '', originalQuery = '' ] = originalUrl.split('?');

    const queryString = extendQuery(originalQuery, query);
    const hashString = extendQuery(originalHash, hash);

    if (queryString) {
        originalUrl = `${ originalUrl }?${ queryString }`;
    }

    if (hashString) {
        originalUrl = `${ originalUrl }#${ hashString }`;
    }

    return originalUrl as HTTPUrl;
};

let sessionStorageEnabled : boolean | undefined;

export const isSessionStorageEnabled = () : boolean => {
    if (sessionStorageEnabled !== undefined) {
        return sessionStorageEnabled;
    }

    try {
        if (typeof window === 'undefined') {
            return false;
        }

        const testKey = 'onetext_session_storage_test';
        sessionStorage.setItem(testKey, 'test');
        sessionStorage.removeItem(testKey);

        sessionStorageEnabled = true;
        return true;
    } catch {
        sessionStorageEnabled = false;
        return false;
    }
};

const memorySessionStorage : {
    [ key : string ] : string | undefined,
} = {};

export const sessionStorageSet = (key : string, value : string) : string => {
    memorySessionStorage[key] = value;

    try {
        if (isSessionStorageEnabled()) {
            sessionStorage.setItem(key, value);
        }
    } catch {
        // pass
    }

    return value;
};

export const sessionStorageGet = (key : string) : string | undefined => {
    const memoryValue = memorySessionStorage[key];

    if (memoryValue !== undefined) {
        return memoryValue;
    }

    try {
        if (isSessionStorageEnabled()) {
            const value = sessionStorage.getItem(key) ?? undefined;

            if (value !== undefined) {
                memorySessionStorage[key] = value;
            }

            return value;
        }
    } catch {
        // pass
    }

    return undefined;
};

export const sessionStorageRemove = (key : string) : void => {
    delete memorySessionStorage[key];

    try {
        if (isSessionStorageEnabled()) {
            sessionStorage.removeItem(key);
        }
    } catch {
        // pass
    }
};
