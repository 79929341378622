/** @jsx h */

import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import type { ExternalID } from '../../../types';

import { getLivePopup } from '../../../api';
import { getBrandSDKPopupID, isEditorMode, promiseTry } from '../../../lib';

import type { GetPopupAPIResponse, HTTPUrl } from '@onetext/api';

type PopupComponentProps = {
    popup : GetPopupAPIResponse,
};

export type PopupComponent<ChildType extends JSX.Element > = (
    props : PopupComponentProps
) => ChildType | null;

export type ConsentMultiPopupThemes<ChildType extends JSX.Element > = {
    [ key : ExternalID ] : PopupComponent<ChildType>,
};

type ConsentMultiPopupProps<ChildType extends JSX.Element > = {
    script : HTMLScriptElement,
    themes : ConsentMultiPopupThemes<ChildType>,
};

export const ConsentMultiPopup = <ChildType extends JSX.Element >({
    script,
    themes
} : ConsentMultiPopupProps<ChildType>) : JSX.Element | null => {
    const [ popup, setPopup ] = useState<GetPopupAPIResponse | undefined>();
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const externalIDOverride = getBrandSDKPopupID(script);
        const finalExternalIDOverride = externalIDOverride && externalIDOverride in themes
            ? externalIDOverride
            : undefined;

        void promiseTry(async () => {
            // Instead of getting live popup, show registered popup in editor
            if (isEditorMode()) {
                return;
            }

            const popupData = await getLivePopup({
                externalIDOverride: finalExternalIDOverride,
                url:                window.location.href as HTTPUrl
            });

            if (popupData.popupFound) {
                setPopup(popupData);
            }
        }).then(() => setIsLoading(false));
    }, [ script, themes ]);

    const PopupComponent = popup?.externalID && popup.externalID in themes
        ? themes[popup.externalID] as PopupComponent<JSX.Element>
        : undefined;

    if (isLoading || !popup || !PopupComponent) {
        return null;
    }

    return (
        <PopupComponent popup={ popup } />
    );
};
