import { callOneTextAPI } from './api';

import type { GetPublicProfileAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const getPublicProfile = async () : Promise<GetPublicProfileAPI['response']> => {
    const response = await callOneTextAPI<GetPublicProfileAPI['response']>({
        method: HTTP_METHOD.GET,
        path:   'profile/public'
    });

    return response.body;
};
