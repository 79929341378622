import type { JSX } from 'react';

import React from 'react';

import { clsx } from '../../jsx';

import type { PopupCommonStyle, PopupConfig, PopupFieldConfig } from '@onetext/api';

type BaseTextFieldProps = {
    className ?: string,
    config ?: PopupConfig,
    isValid ?: boolean,
    style ?: PopupCommonStyle,
    defaultValue ?: string | undefined,
} & Omit<JSX.IntrinsicElements['input'], 'style' >;

export const BaseTextField = ({
    isValid,
    className,
    config,
    style,
    ...props
} : BaseTextFieldProps) : JSX.Element => {
    const fieldStyle : PopupFieldConfig['style'] = config?.component?.field?.style ?? {};

    return (
        <input
            className={
                clsx(
                    'border rounded px-2.5 py-2 min-w-[200px] w-full h-12 outline-none text-black max-w-[384px]',
                    isValid
                        ? 'border-slate-500'
                        : 'border-red-500',
                    className
                )
            }
            style={
                {
                    ...fieldStyle,
                    ...style
                }
            }
            { ...props }
        />
    );
};
