import type { JSX } from 'react';

import React from 'react';

import { clsx } from '../../jsx';
import { isEditorMode } from '../../lib';

import type { PopupCommonStyle } from '@onetext/api';
import { CLOSE_BUTTON_SIZE } from '@onetext/api';

type BaseCloseButtonProps = {
    delay ?: boolean,
    className ?: string,
    size ?: CLOSE_BUTTON_SIZE,
    animate ?: boolean,
    style ?: PopupCommonStyle,
} & Omit<JSX.IntrinsicElements['button'], 'size' | 'style'>;

export const BaseCloseButton = ({
    delay = true,
    className,
    size = CLOSE_BUTTON_SIZE.LG,
    animate = true,
    style,
    ...props
} : BaseCloseButtonProps) : JSX.Element => {

    const buttonSize = {
        [CLOSE_BUTTON_SIZE.XS]: 'w-3 h-3',
        [CLOSE_BUTTON_SIZE.SM]: 'w-4 h-4',
        [CLOSE_BUTTON_SIZE.MD]: 'w-5 h-5',
        [CLOSE_BUTTON_SIZE.LG]: 'w-6 h-6'
    }[size];

    const svgSize = {
        [CLOSE_BUTTON_SIZE.XS]: '12',
        [CLOSE_BUTTON_SIZE.SM]: '16',
        [CLOSE_BUTTON_SIZE.MD]: '20',
        [CLOSE_BUTTON_SIZE.LG]: '25'
    }[size];

    const strokeWidth = {
        [CLOSE_BUTTON_SIZE.XS]: '2.5',
        [CLOSE_BUTTON_SIZE.SM]: '1.75',
        [CLOSE_BUTTON_SIZE.MD]: '1.3',
        [CLOSE_BUTTON_SIZE.LG]: '1.1'
    }[size];

    return (
        <button
            type={ 'button' }
            className={
                clsx(
                    buttonSize,
                    animate && 'fade-in-up',
                    animate && (delay
                        ? 'animation-delay-[0.75s]'
                        : 'animation-delay-[0s]'),
                    className
                )
            }
            style={
                {
                    position: isEditorMode()
                        ? 'absolute'
                        : 'fixed',
                    top:     '20px',
                    right:   '20px',
                    cursor:  'pointer',
                    outline: 'none',
                    zIndex:  50,
                    ...style
                }
            }
            aria-label={ 'Close offer' }
            { ...props }
        >
            <svg
                width={ svgSize }
                height={ svgSize }
                viewBox={ '0 0 25 25' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={ 'M2.97266 2.97168L22.1799 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ strokeWidth } />
                <path
                    d={ 'M22.1799 2.97168L2.97266 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ strokeWidth } />
            </svg>
        </button>
    );
};
