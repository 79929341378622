/** @jsx h */

import { h } from 'preact';
import { useState } from 'preact/hooks';

const CheckIcon = () : JSX.Element => (
    <svg
        width={ '64' }
        height={ '64' }
        viewBox={ '0 0 64 64' }
        fill={ 'none' }
        xmlns={ 'http://www.w3.org/2000/svg' }
    >
        <path
            d={ 'M8 34.7123L26.1463 50L56 14' }
            stroke={ '#3835F6' }
            strokeWidth={ '6' }
            strokeLinecap={ 'round' }
            strokeLinejoin={ 'round' }
        />
    </svg>
);

type ConsentPopupCaptchaProps = {
    images : Array<{
        url : string,
    }>,
};

export const ConsentPopupCaptcha = ({ images } : ConsentPopupCaptchaProps) : JSX.Element | null => {
    const [ selectedImagesIndex, setSelectedImageIndex ] = useState(new Set<number>());

    const handleImageSelect = (selectedImageIndex : number) : void => {
        setSelectedImageIndex((prevSet) => {
            const newSet = new Set(prevSet);

            if (newSet.has(selectedImageIndex)) {
                newSet.delete(selectedImageIndex);
            } else {
                newSet.add(selectedImageIndex);
            }

            return newSet;
        });
    };

    return (
        <div className={ 'grid grid-cols-3 grid-rows-3 gap-1 bg-black' }>
            {
                images.map((image, imageIdx) => {
                    const isSelected = selectedImagesIndex.has(imageIdx);
                    return (
                        <div
                            className={ 'relative cursor-pointer' }
                            onClick={ () => handleImageSelect(imageIdx) }
                        >
                            <div
                                className={
                                    `absolute inset-0 z-[1] flex items-center justify-center w-full h-full transition-all duration-200 ease-in-out ${
                                    isSelected
                                        ? 'bg-blue-600/35 opacity-100'
                                        : 'opacity-0 hover:bg-blue-600/35 hover:opacity-100'
                                    }`
                                }
                            >
                                {
                                    isSelected
                                        ? <CheckIcon />
                                        : null
                                }
                            </div>
                            <img
                                key={ image.url }
                                src={ image.url }
                                className={
                                    `object-cover w-[100px] h-[100px] cursor-pointer transition-all duration-200 ease-in-out ${
                                    isSelected
                                        ? 'scale-[1.03] border-2 border-[#3835F6]'
                                        : 'scale-100'
                                    } ${
                                        imageIdx === 0
                                            ? 'rounded-tl-lg'
                                            : ''
                                    } ${
                                        imageIdx === 2
                                            ? 'rounded-tr-lg'
                                            : ''
                                    } ${
                                        imageIdx === 6
                                            ? 'rounded-bl-lg'
                                            : ''
                                    } ${
                                        imageIdx === 8
                                            ? 'rounded-br-lg'
                                            : ''
                                    }`
                                }
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};
