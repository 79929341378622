import type { PopupButtonConfig } from '@onetext/api';
import { FONT_WEIGHT, TEXT_TRANSFORM } from '@onetext/api';

export const DEFAULT_BUTTON_CONFIG : PopupButtonConfig = {
    level: {
        primary: {
            style: {
                text: {
                    base:  '#ffffff',
                    hover: '#ffffff'
                },
                background: {
                    base:  '#2563eb',
                    hover: '#1d4ed8'
                }
            }
        },
        secondary: {
            style: {
                text: {
                    base:  '#1d4ed8',
                    hover: '#1d4ed8'
                },
                background: {
                    base:  '#dbeafe',
                    hover: '#bfdbfe'
                }
            }
        }
    },
    size: {
        small: {
            style: {
                textTransform: TEXT_TRANSFORM.UPPERCASE,
                height:        '36px',
                borderRadius:  '4px',
                fontSize:      '14px',
                lineHeight:    '1.5',
                fontWeight:    FONT_WEIGHT._500,
                letterSpacing: '0.5px',
                padding:       '8px 16px'
            }
        },
        medium: {
            style: {
                textTransform: TEXT_TRANSFORM.UPPERCASE,
                height:        '40px',
                borderRadius:  '4px',
                fontSize:      '16px',
                lineHeight:    '1.5',
                fontWeight:    FONT_WEIGHT._500,
                letterSpacing: '0.5px',
                padding:       '12px 24px'
            }
        },
        large: {
            style: {
                textTransform: TEXT_TRANSFORM.UPPERCASE,
                height:        '56px',
                borderRadius:  '4px',
                fontSize:      '18px',
                lineHeight:    '1.5',
                fontWeight:    FONT_WEIGHT._500,
                letterSpacing: '0.5px',
                padding:       '14px 28px'
            }
        },
        default: {
            style: {
                textTransform: TEXT_TRANSFORM.UPPERCASE,
                height:        '48px',
                borderRadius:  '4px',
                fontSize:      '16px',
                lineHeight:    '1.5',
                fontWeight:    FONT_WEIGHT._500,
                letterSpacing: '0.5px',
                padding:       '12px 24px'
            }
        }
    }
} as const;

export const DEFAULT_STYLE_CONFIG = {
    color: {
        background: '#ffffff',
        text:       '#000000'
    },
    font: {
        family: {
            primary:   'Helvetica Neue',
            secondary: 'sans-serif'
        }
    }
} as const;

export const DEFAULT_TEASER_CONFIG = {
    style: {
        color: {
            text: {
                base:  '#000',
                hover: '#000'
            },
            background: {
                base:  '#fff',
                hover: '#fff'
            },
            border: {
                base:  '#000',
                hover: '#000'
            }
        }
    }
} as const;
