import type { ComponentChild, JSX } from 'react';

import React from 'react';

import { DEFAULT_STYLE_CONFIG, Z_INDEX_MAX } from '../../constants';
import { clsx } from '../../jsx';
import { assertUnreachable, isEditorMode, run } from '../../lib';

import type { PopupConfig } from '@onetext/api';
import { POPUP_LAYOUT } from '@onetext/api';

type BasePopupProps = {
    children ?: ComponentChild,
    layout ?: POPUP_LAYOUT,
    background ?: ComponentChild,
    overlayClassName ?: string,
    modalClassName ?: string,
    config ?: PopupConfig,
    closeButton : ComponentChild,
};

export const BasePopup = ({
    children,
    layout = POPUP_LAYOUT.MODAL,
    background,
    overlayClassName,
    modalClassName,
    config,
    closeButton
} : BasePopupProps) : JSX.Element => {
    const fontFamily = config?.style?.font?.family?.primary ?? DEFAULT_STYLE_CONFIG.font.family.primary;
    const textColor = config?.style?.color?.text ?? DEFAULT_STYLE_CONFIG.color.text;
    const backgroundColor = config?.style?.color?.background ?? DEFAULT_STYLE_CONFIG.color.background;

    return (
        <div
            id={ 'ot-popup-overlay' }
            className={
                clsx(
                    'w-full h-full',
                    isEditorMode()
                        ? 'absolute inset-0'
                        : 'fixed inset-0',
                    'fade-in-up',
                    overlayClassName
                )
            }
            style={
                {
                    zIndex: isEditorMode()
                        ? 0
                        : Z_INDEX_MAX
                }
            }
        >
            <div
                id={ 'ot-popup-modal' }
                className={
                    clsx(
                        run(() => {
                            switch (layout) {
                                case POPUP_LAYOUT.FULLSCREEN:
                                    return `w-full h-full top-0 left-0 ${
                                    isEditorMode()
                                        ? 'absolute'
                                        : 'fixed' }`;
                                case POPUP_LAYOUT.MODAL:
                                    return 'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ' +
                                        'w-full md:w-auto min-w-[40vw] min-h-[40vh]';
                                default:
                                    throw assertUnreachable(layout);
                            }
                        }),
                        'flex flex-col justify-center items-center',
                        modalClassName
                    )
                }
                style={
                    {
                        fontFamily: `'${ fontFamily }', sans-serif`,
                        color:      textColor,
                        backgroundColor,
                        overflow:   'hidden'
                    }
                }
            >
                { background }
                <div className={ 'relative w-full h-full z-10' }>
                    { children }
                </div>
                { closeButton }
            </div>
        </div>
    );
};
