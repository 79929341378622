/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';

import { BasePopupTerms } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupTermsProps = {
    children ?: ComponentChildren,
    brandName ?: string,
    termsUrl ?: string,
    privacyUrl ?: string,
    className ?: string,
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupTerms = ({
    brandName,
    termsUrl,
    privacyUrl,
    className,
    children,
    overrideStyles
} : ConsentPopupTermsProps) : JSX.Element => {
    const { popup, isDesktopMode } = useConsentPopupContext();

    return (
        <BasePopupTerms
            brandName={ brandName }
            termsUrl={ termsUrl }
            privacyUrl={ privacyUrl }
            className={ className }
            config={ popup.config }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        >
            { children }
        </BasePopupTerms>
    );
};
