/** @jsx h */

import { h } from 'preact';

import { assertUnreachable, track } from '../../../lib';
import { BaseCloseButton } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle, TOKEN, TokenType } from '@onetext/api';
import { CLOSE_BUTTON_SIZE, MODAL_TYPE } from '@onetext/api';

const logPopupClose = (accountToken : TokenType<TOKEN.ACCOUNT> | undefined) : void => {
    track('consent_popup_close_button_click', {
        accountToken
    });
};

const logTeaserClose = (accountToken : TokenType<TOKEN.ACCOUNT> | undefined) : void => {
    track('consent_teaser_close_button_click', {
        accountToken
    });
};

type CloseButtonProps = {
    delay ?: boolean,
    className ?: string,
    size ?: CLOSE_BUTTON_SIZE,
    animate ?: boolean,
    type : MODAL_TYPE,
    overrideStyles ?: PopupResponsiveStyle,
};

export const CloseButton = ({
    delay = true,
    className,
    size = CLOSE_BUTTON_SIZE.LG,
    animate = true,
    type,
    overrideStyles
} : CloseButtonProps) : JSX.Element => {
    const { closePopup, closeTeaser, accountToken, isDesktopMode } = useConsentPopupContext();

    const handleClose = (event : Event) : void => {
        event.stopPropagation();

        switch (type) {
            case MODAL_TYPE.POPUP:
                logPopupClose(accountToken);
                closePopup();
                break;
            case MODAL_TYPE.TEASER:
                logTeaserClose(accountToken);
                closeTeaser();
                break;
            default:
                throw assertUnreachable(type);
        }
    };

    return (
        <BaseCloseButton
            onClick={ handleClose }
            delay={ delay }
            className={ className }
            size={ size }
            animate={ animate }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        />
    );
};
