/** @jsx h */

import type { ComponentChildren } from 'preact';

import { h } from 'preact';
import { useEffect } from 'preact/hooks';

import { trackBuyerEvent } from '../../../biz';
import { track } from '../../../lib';
import { BasePopup } from '../../base';
import { Dialog } from '../../dialog';
import { useConsentPopupContext } from '../context';

import { CloseButton } from './close-button';

import { BUYER_EVENT, MODAL_TYPE, POPUP_LAYOUT } from '@onetext/api';

type ConsentPopupProps = {
    children ?: ComponentChildren,
    layout ?: POPUP_LAYOUT,
    background ?: JSX.Element,
    overlayClassName ?: string,
    modalClassName ?: string,
};

export const ConsentPopup = ({
    children,
    layout = POPUP_LAYOUT.MODAL,
    background,
    overlayClassName,
    modalClassName
} : ConsentPopupProps) : JSX.Element | null => {
    const {
        accountToken,
        activePageID,
        isPopupOpen,
        suppressPopup,
        popup,
        effectivePopupToken
    } = useConsentPopupContext();

    useEffect(() => {
        if (activePageID) {
            const EVENT_NAME = 'consent_popup_page_render';

            track(EVENT_NAME, {
                activePageID
            });

            trackBuyerEvent(
                {
                    buyerEvent: BUYER_EVENT.POPUP_PAGE_RENDER,
                    payload:    {
                        popupToken:       effectivePopupToken,
                        activePageNumber: activePageID
                    }
                }
            );
        }
    }, [ activePageID, effectivePopupToken ]);

    useEffect(() => {
        track('consent_popup_init', {
            accountToken
        });

        if (suppressPopup.suppressed) {
            track('consent_popup_suppress', {
                reason: suppressPopup.reason
            });
        } else {
            track('consent_popup_render', {
                accountToken
            });
        }
    }, [ suppressPopup.suppressed ]);

    if (!isPopupOpen || suppressPopup.suppressed) {
        return null;
    }

    return (
        <Dialog>
            <BasePopup
                closeButton={
                    <CloseButton type={ MODAL_TYPE.POPUP } />
                }
                background={ background }
                layout={ layout }
                overlayClassName={ overlayClassName }
                modalClassName={ modalClassName }
                config={ popup.config }
            >
                { children }
            </BasePopup>
        </Dialog>
    );
};
