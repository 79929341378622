/** @jsx h */
import type { CSSProperties, ReactNode } from 'preact/compat';

import { h, toChildArray } from 'preact';

import { BasePopupColumn, BasePopupColumns, BasePopupRow } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupLayoutProps = {
    children : ReactNode,
    className ?: string,
    style ?: CSSProperties,
    overrideStyles ?: PopupResponsiveStyle,
};

const defaultStyle : CSSProperties = {};

export const ConsentPopupRow = ({
    children,
    className = '',
    style = defaultStyle,
    overrideStyles
} : ConsentPopupLayoutProps) : JSX.Element => {
    const { isDesktopMode } = useConsentPopupContext();
    return (
        <BasePopupRow
            className={ className }
            style={
                {
                    ...style,
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        >
            { children }
        </BasePopupRow>
    );
};

export const ConsentPopupColumns = ({
    children,
    className = '',
    style = defaultStyle,
    overrideStyles
} : ConsentPopupLayoutProps) : JSX.Element => {
    const { isDesktopMode } = useConsentPopupContext();

    const childrenArray = toChildArray(children);
    const childCount = isDesktopMode
        ? childrenArray.length
        : 1;

    return (
        <BasePopupColumns
            className={
                className
            }
            style={
                {
                    ...style,
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
            childCount={ childCount }
        >
            { children }
        </BasePopupColumns>
    );
};

export const ConsentPopupColumn = ({
    children,
    className = '',
    style = defaultStyle,
    overrideStyles
} : ConsentPopupLayoutProps) : JSX.Element => {
    const { isDesktopMode } = useConsentPopupContext();

    return (
        <BasePopupColumn
            className={
                className
            }
            style={
                {
                    ...style,
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        >
            { children }
        </BasePopupColumn>
    );
};
