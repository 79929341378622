/** @jsx h */

import { h } from 'preact';
import { useContext, useMemo, useState } from 'preact/hooks';
import QRCode from 'react-qr-code';
import { twMerge } from 'tailwind-merge';

import { getBasicSwitchToSMSThreadLink, switchToSMSThread } from '../../api';

import { ConciergeContext } from './container';

export const DesktopCard = () : JSX.Element | null => {
    const [ messageSubmitted, setMessageSubmitted ] = useState(false);
    const [ url, setUrl ] = useState<string | null>(null);
    const [ isTransitioning, setIsTransitioning ] = useState(false);

    const conciergeContext = useContext(ConciergeContext);

    if (!conciergeContext) {
        return null;
    }

    const handleSubmit = (e : Event) : void => {
        e.preventDefault();

        const fullMessage = `${ conciergeContext.optinMessage }\n\nI'm looking for: ${ (e.target as HTMLFormElement).querySelector('textarea')?.value ?? '' }`;

        setUrl(getBasicSwitchToSMSThreadLink({
            message: fullMessage
        }));

        try {
            void switchToSMSThread({
                message: fullMessage
            });
        } catch {
            // ignore
        }

        setIsTransitioning(true);

        setTimeout(() => {
            setMessageSubmitted(true);

            setTimeout(() => {
                setIsTransitioning(false);
            }, 300); // Allow time for animation to complete
        }, 10);
    };

    const showQRCode = useMemo(() => messageSubmitted && url, [ messageSubmitted, url ]);

    return (
        <div
            className={
                twMerge(
                    [ 'w-[250px]',
                        showQRCode
                            ? 'h-[429px]'
                            : '',
                        'rounded-xl',
                        'shadow-xl',
                        'p-4', 'py-6' ],
                    [ 'bg-white' ],
                    'font-sans',
                    'flex flex-col',
                    'justify-between items-center',
                    'transition-all duration-300 ease-in-out'
                )
            }
        >
            <div>
                <div className={ 'flex justify-between items-start mb-4' }>
                    <div className={ 'flex-1' }>
                        <h3
                            style={ { color: conciergeContext.brandPrimaryColor } }
                            className={
                                twMerge(
                                    'text-xl font-semibold mb-2'
                                )
                            }
                        >
                            { 'Concierge Shopper' }
                        </h3>
                        <p className={ 'text-gray-600 text-xs' }>
                            { 'Our live concierge will shop for your preferences and help you access discounts.' }
                        </p>
                    </div>
                </div>

                {
                    (showQRCode || isTransitioning)
                        ? (
                            <div
                                className={
                                    twMerge(
                                        'mt-2 flex flex-col items-center relative',
                                        showQRCode
                                            ? 'opacity-100'
                                            : 'opacity-0',
                                        'transition-opacity duration-300 ease-in-out'
                                    )
                                }
                            >
                                <svg
                                    className={ 'absolute top-[12px] left-[10px] z-10' }
                                    width={ '56' }
                                    height={ '52' }
                                    viewBox={ '0 0 56 52' }
                                    fill={ 'none' }
                                    xmlns={ 'http://www.w3.org/2000/svg' }
                                >
                                    <path
                                        d={
                                            'M2 28.0267C2 32.2933 4.99467 36.0107 9.21867 ' +
                                            '36.632C12.1173 37.0587 15.0453 37.3867 18 ' +
                                            '37.616V50L28.8693 39.1307C29.6065 38.3983 30.5959 ' +
                                            '37.9756 31.6347 37.9493C36.7051 37.809 41.7628 37.3691 ' +
                                            '46.7813 36.632C51.0053 36.0107 54 32.296 54 28.024V11.976C54 ' +
                                            '7.70401 51.0053 3.98934 46.7813 3.36801C40.5626 2.45524 34.2854 1.99803 ' +
                                            '28 2.00001C21.6213 2.00001 15.3493 2.46667 9.21867 3.36801C4.99467 ' +
                                            '3.98934 2 7.70667 2 11.976V28.024V28.0267Z'
                                        }
                                        fill={ 'white' }
                                        stroke={ conciergeContext.brandPrimaryColor }
                                        strokeWidth={ '3' }
                                        strokeLinecap={ 'round' }
                                        strokeLinejoin={ 'round' }
                                    />
                                </svg>

                                <svg
                                    className={ 'absolute top-[141px] right-[2px] z-10' }
                                    width={ '40' }
                                    height={ '37' }
                                    viewBox={ '0 0 40 37' }
                                    fill={ 'none' }
                                    xmlns={ 'http://www.w3.org/2000/svg' }
                                >
                                    <path
                                        d={
                                            'M38.3281 19.6592C38.3281 22.6258 ' +
                                            '36.2459 25.2105 33.3089 25.6426C31.2934 ' +
                                            '25.9392 29.2575 26.1673 27.2031 26.3268V34.9375L19.6455 ' +
                                            '27.3799C19.133 26.8707 18.4451 26.5768 17.7228 26.5585C14.1972 26.4609 ' +
                                            '10.684 26.1699 7.19111 25.6426C4.2541 25.2105 2.17188 22.6276 2.17188 ' +
                                            '19.6573V8.49894C2.17188 5.52857 4.2541 2.94571 7.19111 2.51369C11.5151 ' +
                                            '1.87904 15.8797 1.56113 20.25 1.5625C24.6852 1.5625 29.0462 ' +
                                            '1.88698 33.3089 2.51369C36.2459 2.94571 38.3281 5.53042 38.3281 ' +
                                            '8.49894V19.6573V19.6592Z'
                                        }
                                        fill={ 'white' }
                                        stroke={ conciergeContext.brandPrimaryColor }
                                        strokeWidth={ '3' }
                                        strokeLinecap={ 'round' }
                                        strokeLinejoin={ 'round' }
                                    />
                                </svg>

                                <div
                                    style={
                                        {
                                            borderColor:  conciergeContext.brandPrimaryColor,
                                            borderRadius: '35% 35% 0 0',
                                            borderWidth:  '4px',
                                            padding:      '10px',
                                            width:        '55px',
                                            height:       '44px',
                                            position:     'relative',
                                            top:          '3px',
                                            borderBottom: '0'
                                        }
                                    }
                                />

                                <div className={ 'rounded-lg flex items-center justify-center mb-4' }>
                                    {
                                        showQRCode && url
                                            ? (
                                                <a
                                                    style={ { borderColor: conciergeContext.brandPrimaryColor } }
                                                    className={
                                                        twMerge(
                                                            'rounded cursor-pointer',
                                                            'border-4 p-4 rounded-2xl',
                                                            'opacity-0 transform scale-95',
                                                            showQRCode
                                                                ? 'opacity-100 scale-100'
                                                                : '',
                                                            'transition-all duration-300 ease-in-out'
                                                        )
                                                    }
                                                    href={ url }
                                                    target={ '_blank' }
                                                    rel={ 'noopener noreferrer' }
                                                >
                                                    <QRCode
                                                        value={ url }
                                                        size={ 120 }
                                                        bgColor={ 'white' }
                                                        fgColor={ conciergeContext.brandPrimaryColor }
                                                    />
                                                </a>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                        )
                        : null
                }
            </div>

            {
                showQRCode
                    ? (
                        <p
                            style={ { backgroundColor: `${ conciergeContext.brandPrimaryColor }1A` } }
                            className={
                                twMerge(
                                    'text-xs text-center',
                                    'text-gray-600 font-medium',
                                    'py-2 px-2',
                                    'rounded-lg',
                                    'opacity-0',
                                    showQRCode && !isTransitioning
                                        ? 'opacity-100'
                                        : '',
                                    'transition-opacity duration-300 ease-in-out'
                                )
                            }
                        >
                            { 'Scan with your mobile device to message our concierge' }
                        </p>
                    )
                    : (
                        <div className={ 'rounded-lg flex items-center justify-center' }>
                            <form
                                className={ 'w-full mb-0' }
                                onSubmit={ handleSubmit }
                            >
                                <textarea
                                    name={ 'conciergeMessage' }
                                    placeholder={ 'I\'m looking for...' }
                                    style={ { color: conciergeContext.brandPrimaryColor } }
                                    className={
                                        twMerge(
                                            'w-full',
                                            'p-2',
                                            'border border-gray-300',
                                            'rounded-lg',
                                            'text-gray-600',
                                            'text-sm',
                                            'focus:outline-none',
                                            'transition-colors',
                                            'duration-200',
                                            'mb-2',
                                            'resize-none'
                                        )
                                    }
                                />
                                <button
                                    type={ 'submit' }
                                    style={ { backgroundColor: conciergeContext.brandPrimaryColor } }
                                    className={
                                        twMerge(
                                            'w-full',
                                            'p-2',
                                            'text-white',
                                            'text-sm',
                                            'font-medium',
                                            'rounded-lg',
                                            'hover:bg-opacity-80',
                                            'transition-colors',
                                            'duration-200'
                                        )
                                    }
                                >
                                    { 'Send' }
                                </button>
                            </form>
                        </div>
                    )
            }
        </div>
    );
};
