import type { JSX } from 'react';

import React from 'react';

import type { PopupCommonStyle } from '@onetext/api';

type BaseImageProps = {
    style ?: PopupCommonStyle,
    src ?: string,
    alt ?: string,
    shouldSubmitPage ?: boolean,
    submitPage ?: () => Promise<void>,
};

export const BaseImage = ({
    style, src, alt, submitPage, shouldSubmitPage
} : BaseImageProps) : JSX.Element => {

    return (
        <img
            className={
                shouldSubmitPage
                    ? 'cursor-pointer'
                    : ''
            }
            onClick={
                shouldSubmitPage
                    ? () => void submitPage?.()
                    : undefined
            }
            src={ src }
            alt={ alt }
            style={ style }
        />
    );
};
