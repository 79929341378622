/** @jsx h */
import type { ComponentChildren } from 'preact';

import { h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';

import type { PageChildrenRenderFunction, PageChildrenRenderProps } from '../../../types';

import { BasePopupPage } from '../../base';
import { ConsentPopupPageContext, useConsentPopupContext } from '../context';

import { ConsentPopupForm } from './form';

import type { COUNTRY, Milliseconds } from '@onetext/api';

type ConsentPopupPageProps = {
    children : ComponentChildren | PageChildrenRenderFunction,
    className ?: string,
    openKeyboardOnSubmit ?: boolean,
    submitDelay ?: Milliseconds,
    background ?: JSX.Element,
    onSubmitStart ?: () => Promise<void> | void,
    shownToCountries ?: Array<COUNTRY>,
};

export const ConsentPopupPage = ({
    children,
    className = undefined,
    openKeyboardOnSubmit = false,
    submitDelay,
    onSubmitStart,
    shownToCountries,
    background
} : ConsentPopupPageProps) : JSX.Element | null => {
    const {
        popup,
        activePageID,
        registerPage,
        submitPage,
        getNewPageID,
        properties
    } = useConsentPopupContext();

    const [ pageID ] = useState(getNewPageID);

    useEffect(() => {
        const shouldRegister = !shownToCountries || (
            popup.customerCountry &&
            shownToCountries.includes(popup.customerCountry)
        );

        if (shouldRegister) {
            return registerPage(pageID);
        }
    }, [ pageID, registerPage, shownToCountries, popup.customerCountry ]);

    if (activePageID !== pageID) {
        return null;
    }

    const pageContext = useMemo(() => {
        return {
            openKeyboardOnSubmit
        };
    }, [ openKeyboardOnSubmit ]);

    const renderProps : PageChildrenRenderProps = {
        properties,
        customerCountry: popup.customerCountry
    };

    const renderChildren = typeof children === 'function'
        ? (children as PageChildrenRenderFunction)(renderProps)
        : children;

    return (
        <ConsentPopupPageContext.Provider value={ pageContext }>
            <ConsentPopupForm
                onSubmitStart={ onSubmitStart }
                onSubmit={ submitPage }
                submitDelay={ submitDelay }
                openKeyboardOnSubmit={ openKeyboardOnSubmit }
            >
                <BasePopupPage
                    className={ className }
                    background={ background }
                >
                    { renderChildren }
                </BasePopupPage>
                { background }
            </ConsentPopupForm>
        </ConsentPopupPageContext.Provider>
    );
};
