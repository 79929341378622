import type { JSX } from 'react';

import React from 'react';

import { clsx } from '../../jsx';
import { isEditorMode } from '../../lib';

import type { PopupCommonStyle, PopupConfig } from '@onetext/api';
import { TEMPLATE_CANVAS_TYPE } from '@onetext/api';

type BaseBackgroundProps = {
    config ?: PopupConfig,
    style ?: PopupCommonStyle,
};

export const BaseBackground = ({
    config, style
} : BaseBackgroundProps) : JSX.Element | null => {

    const backgroundImageSrc = config?.template?.background?.type &&
        config.template.background.type === TEMPLATE_CANVAS_TYPE.SINGLE_IMAGE
        ? config.template.background.src
        : '';

    const filterBlur = style?.filterBlur;
    const filterBrightness = style?.filterBrightness;

    if (!backgroundImageSrc) {
        return null;
    }

    return (
        <div
            className={
                clsx('absolute inset-0', isEditorMode()
                    ? 'z-0'
                    : '-z-10')
            }
        >
            <div
                className={
                    clsx(
                        'absolute inset-0',
                        'w-full h-full bg-no-repeat bg-cover bg-center'
                    )
                }
                style={
                    {
                        backgroundImage: `url(${ backgroundImageSrc })`,
                        filter:          `${ filterBlur
                            ? `blur(${ filterBlur })`
                            : '' } ${ filterBrightness
                                ? `brightness(${ filterBrightness })`
                                : '' }`,
                        ...style
                    }
                }
            />
        </div>
    );
};
