import { Fragment } from 'preact';

import { coreFlowTypes, getCustomer, launchCoreFlow, launchCustomFlow, submitCustomerOptIn } from './biz';
import {
    ConsentMultiPopup, ConsentPopup, ConsentPopupBackground, ConsentPopupButton, ConsentPopupCaptcha,
    ConsentPopupColumn, ConsentPopupColumns, ConsentPopupContainer, ConsentPopupEmailField, ConsentPopupImage,
    ConsentPopupLogo, ConsentPopupPage, ConsentPopupPhoneField, ConsentPopupQuiz, ConsentPopupQuizOption,
    ConsentPopupRow, ConsentPopupSpinner, ConsentPopupTeaser, ConsentPopupTerms, ConsentPopupText,
    ConsentPopupVerticalSpacer, registerPopup, renderPopup, SPINNER_ANIMATION
} from './components';
import { clsx, deepmerge, element, render, useEffect, useState } from './jsx';
import { getURLQueryParam, isDevelopment, isPreviewMode, trackWebsiteView } from './lib';

export const customer = {
    get: getCustomer
};

export const flow = {
    core: {
        types: coreFlowTypes,
        start: launchCoreFlow
    },
    custom: {
        start: launchCustomFlow
    }
};

export const optin = {
    submit: submitCustomerOptIn
};

export const jsx = {
    element,
    render,
    useState,
    useEffect,
    clsx,
    deepmerge
};

export const component = {
    consent: {
        popup: {
            MultiPopup:     ConsentMultiPopup,
            Popup:          ConsentPopup,
            Page:           ConsentPopupPage,
            Button:         ConsentPopupButton,
            EmailField:     ConsentPopupEmailField,
            PhoneField:     ConsentPopupPhoneField,
            Quiz:           ConsentPopupQuiz,
            QuizOption:     ConsentPopupQuizOption,
            Row:            ConsentPopupRow,
            Columns:        ConsentPopupColumns,
            Column:         ConsentPopupColumn,
            VerticalSpacer: ConsentPopupVerticalSpacer,
            Spinner:        ConsentPopupSpinner,
            Terms:          ConsentPopupTerms,
            Teaser:         ConsentPopupTeaser,
            PopupContainer: ConsentPopupContainer,
            Text:           ConsentPopupText,
            Logo:           ConsentPopupLogo,
            Background:     ConsentPopupBackground,
            Captcha:        ConsentPopupCaptcha,
            Image:          ConsentPopupImage,
            Fragment,

            SPINNER_ANIMATION
        }
    }
};

export const sdk = {
    isDevelopment,
    isPreviewMode,
    getURLQueryParam,
    trackWebsiteView
};

export { events } from './biz';

export const popup = {
    render:   renderPopup,
    register: registerPopup
};
