/** @jsx h */

import { createContext, h } from 'preact';
import { useMemo, useState } from 'preact/hooks';

import { getPublicProfile } from '../../api';
import { getStoredBrowserIdentifier } from '../../biz';
import { render } from '../../jsx';
import { isDevice, stringToPercentile, trackError } from '../../lib';
import { Style } from '../style';

import { ConciergeBubble } from './bubble';
import { DesktopCard } from './desktop-card';
import { MobileCard } from './mobile-card';

export type ConciergeContextType = {
    brandPrimaryColor : string,
    isMobile : boolean,
    optinMessage : string,
};

export const ConciergeContext = createContext<ConciergeContextType | undefined>(undefined);

type ConciergeContainerProps = {
    optinMessage : string,
    conciergeWidget ?: {
        enabled : boolean,
        brandPrimaryColor : string,
        bubblePosition ?: {
            mobile : {
                bottom : number,
                right : number,
            },
            desktop : {
                bottom : number,
                right : number,
            },
        },
    },
};

export const ConciergeContainer = ({ conciergeWidget, optinMessage } : ConciergeContainerProps) : JSX.Element => {
    const [ isOpen, setIsOpen ] = useState(false);
    const isMobile = isDevice();

    const contextValues = useMemo(() => ({
        brandPrimaryColor: conciergeWidget?.brandPrimaryColor ?? '#374151',
        isMobile,
        optinMessage
    }), [ conciergeWidget?.brandPrimaryColor, isMobile ]);

    const card = isMobile
        ? <MobileCard />
        : <DesktopCard />;

    const handleToggle = () : void => setIsOpen(prev => !prev);

    const position = isMobile
        ? conciergeWidget?.bubblePosition?.mobile ?? { bottom: 20, right: 20 }
        : conciergeWidget?.bubblePosition?.desktop ?? { bottom: 20, right: 20 };

    return (
        <Style>
            <div
                style={
                    {
                        bottom: `${ position.bottom }px`,
                        right:  `${ position.right }px`
                    }
                }
                className={
                    'fixed z-[2147483647] flex flex-col items-end gap-4'
                }>
                <ConciergeContext.Provider value={ contextValues }>
                    {
                        isOpen
                            ? card
                            : null
                    }
                    <ConciergeBubble onClick={ handleToggle } />
                </ConciergeContext.Provider>
            </div>
        </Style>
    );
};

export const initConcierge = async() : Promise<void> => {
    try {
        const profile = await getPublicProfile();
        const widgetOptions = profile.clientConfig?.conciergeWidget;

        if (!widgetOptions?.enabled || !profile.phone) {
            return;
        }

        const enabledPercentage = widgetOptions.enabledPercentage ?? 100;

        // If the user is not in the enabled percentage, return
        if (stringToPercentile(getStoredBrowserIdentifier()) > enabledPercentage) {
            return;
        }

        const container = document.createElement('div');
        document.body.appendChild(container);

        void render({
            element: <ConciergeContainer
                optinMessage={ profile.messageTemplates.optinWithQuery }
                conciergeWidget={ profile.clientConfig?.conciergeWidget } />,
            container
        });
    } catch (err) {
        trackError(err, {
            type:      'concierge_load_error',
            hardError: false
        });
    }
};
