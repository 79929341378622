import type { JSX } from 'react';

import React from 'react';

import type { TeaserTextRenderFunction, TeaserTextRenderProps } from '../../types';

import { DEFAULT_TEASER_CONFIG, Z_INDEX_MAX } from '../../constants';
import { clsx } from '../../jsx';
import { isEditorMode } from '../../lib';

import type { PopupConfig } from '@onetext/api';
import { CLOSE_BUTTON_POSITION } from '@onetext/api';

type BasePopupTeaserProps = {
    className ?: string,
    customText ?: string | TeaserTextRenderFunction,
    closeButtonPosition ?: CLOSE_BUTTON_POSITION,
    config ?: PopupConfig,
    hover ?: boolean,
    isTeaserOpen : boolean,
    isTeaserSuppressed : boolean,
    properties : Record<string, string>,
    closeButton : JSX.Element,
} & JSX.IntrinsicElements['button'];

export const BasePopupTeaser = ({
    className,
    config,
    customText,
    hover,
    properties,
    isTeaserOpen,
    isTeaserSuppressed,
    closeButton,
    closeButtonPosition = CLOSE_BUTTON_POSITION.RIGHT,
    ...props
} : BasePopupTeaserProps) : JSX.Element | null => {
    const teaserConfig = config?.component?.teaser;

    const fontFamily = config?.style?.font?.family?.primary ?? 'Helvetica Neue';
    const textColor = hover
        ? teaserConfig?.style?.color?.text?.hover ??
        config?.style?.color?.text ??
        DEFAULT_TEASER_CONFIG.style.color.text.hover
        : teaserConfig?.style?.color?.text?.base ??
        config?.style?.color?.text ??
        DEFAULT_TEASER_CONFIG.style.color.text.base;

    const backgroundColor = hover
        ? teaserConfig?.style?.color?.background?.hover ??
        config?.style?.color?.background ??
        DEFAULT_TEASER_CONFIG.style.color.background.hover
        : teaserConfig?.style?.color?.background?.base ??
        config?.style?.color?.background ??
        DEFAULT_TEASER_CONFIG.style.color.background.base;

    const borderColor = hover
        ? teaserConfig?.style?.color?.border?.hover
        : teaserConfig?.style?.color?.border?.base;

    const teaserStyle = teaserConfig?.style?.dimension;

    const renderProps : TeaserTextRenderProps = { properties };
    const teaserText = typeof customText === 'function'
        ? customText(renderProps)
        : customText ?? 'View offer';

    const content = [
        <div
            className={ 'relative w-full h-full flex items-center justify-center z-10' }
            key={ 'teaser-content' }
        >
            { teaserText }
        </div>,
        closeButton
    ];

    if (closeButtonPosition === CLOSE_BUTTON_POSITION.LEFT) {
        content.reverse();
    }

    if (!isTeaserOpen || isTeaserSuppressed) {
        return null;
    }

    return (
        <button
            type={ 'button' }
            className={
                clsx(
                    className,
                    'w-fit hidden md:flex items-center gap-2 py-2.5 px-3 mr-2.5 rounded-[3px] cursor-pointer text-base',
                    isEditorMode()
                        ? 'absolute bottom-5 left-5'
                        : 'fixed bottom-5 left-5',
                    'md:px-3.5 md:mr-3 md:text-lg'
                )
            }
            style={
                {
                    fontFamily: `'${ fontFamily }', sans-serif`,
                    color:      textColor,
                    backgroundColor,
                    zIndex:     Z_INDEX_MAX,
                    ...borderColor
                        ? {
                            border: `1px solid ${ borderColor }`
                        }
                        : {},
                    ...teaserStyle
                }
            }
            aria-label={ 'View offer' }
            { ...props }
        >
            { content }
        </button>
    );
};
