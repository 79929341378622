import type { PopupToken } from '../types';

import { assertUnreachable, localStorageGet, localStorageRemove, localStorageSet } from './util';

import type { Milliseconds, PopupConfig } from '@onetext/api';
import { MODAL_TYPE } from '@onetext/api';

const POPUP_COOLDOWN_MS = 7 * 24 * 60 * 60 * 1000 as Milliseconds; // 7 days in milliseconds
const TEASER_COOLDOWN_MS = 1 * 24 * 60 * 60 * 1000 as Milliseconds; // 1 day in milliseconds

type CooldownParams = {
    token : PopupToken,
    type : MODAL_TYPE,
    brandConfig ?: PopupConfig,
};

const constructKey = ({ token, type } : CooldownParams) : string => {
    const prefix = `ot_${ type }`;
    const parts = [ prefix, token, 'last_closed_at' ];
    return parts.filter(Boolean).join('_');
};

export const setLastClosedTime = (params : CooldownParams) : void => {
    const key = constructKey(params);
    localStorageSet(key, Date.now().toString());
};

const getCooldownMilliseconds = (type : MODAL_TYPE, brandConfig ?: PopupConfig) : Milliseconds => {

    switch (type) {
        case MODAL_TYPE.POPUP:
            return brandConfig?.behavior?.cooldown?.popup?.milliseconds
                ? brandConfig.behavior.cooldown.popup.milliseconds
                : POPUP_COOLDOWN_MS;
        case MODAL_TYPE.TEASER:
            return brandConfig?.behavior?.cooldown?.teaser?.milliseconds
                ? brandConfig.behavior.cooldown.teaser.milliseconds
                : TEASER_COOLDOWN_MS;
        default:
            throw assertUnreachable(type);
    }
};

export const isCoolingDown = ({ token, type, brandConfig } : CooldownParams) : boolean => {
    const key = constructKey({ token, type });
    const lastClosedAt = localStorageGet(key);

    if (!lastClosedAt) {
        return false;
    }

    const cooldownMs = getCooldownMilliseconds(type, brandConfig);
    return (Date.now() - Number.parseInt(lastClosedAt, 10)) < cooldownMs;
};

export const removeLastClosedTime = (params : CooldownParams) : void => {
    const key = constructKey(params);
    localStorageRemove(key);
};
