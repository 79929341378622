/** @jsx h */

import { h } from 'preact';

import { BaseText } from '../../base';
import { useConsentPopupContext } from '../context';

import type { PopupResponsiveStyle } from '@onetext/api';

type ConsentPopupTextProps = {
    content ?: string,
    overrideStyles ?: PopupResponsiveStyle,
};

export const ConsentPopupText = ({
    content,
    overrideStyles
} : ConsentPopupTextProps) : JSX.Element => {
    const { isDesktopMode } = useConsentPopupContext();
    return (
        <BaseText
            text={ content }
            style={
                {
                    ...overrideStyles?.mobile,
                    ...isDesktopMode
                        ? overrideStyles?.desktop
                        : {}
                }
            }
        />
    );
};
