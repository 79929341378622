/** @jsx h */

import { h } from 'preact';

export const ConsentPopupVerticalSpacer = () : JSX.Element => {
    return (
        <div
            className={ `mt-2` }
        />
    );
};
