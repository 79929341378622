import { callOneTextAPI } from './api';

import type { GetLivePopupAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const getLivePopup = async (
    { externalIDOverride, url } : GetLivePopupAPI['request']
) : Promise<GetLivePopupAPI['response']> => {
    const {
        body
    } = await callOneTextAPI<GetLivePopupAPI['response']>({
        method: HTTP_METHOD.GET,
        path:   'popup/live',
        query:  { externalIDOverride, url }
    });

    return body;
};
