import { h, render as preactRender } from 'preact';

import { ErrorBoundary } from '../components';
import { awaitBody } from '../lib';
import { insertSDKStyleSheetElement } from '../style';

type RenderOptions = {
    container ?: Element,
    element : JSX.Element | null,
    shadowDOM ?: boolean,
};

export type RenderReturnType = { destroy : () => void };

export const render = async ({
    container,
    element,
    shadowDOM = true
} : RenderOptions) : Promise<RenderReturnType> => {
    container ??= await awaitBody();

    let finalContainer : Element | ShadowRoot;
    let shadowContainer : HTMLDivElement | undefined;

    if (shadowDOM) {
        shadowContainer = document.createElement('div');
        shadowContainer.style.display = 'block';
        container.appendChild(shadowContainer);

        finalContainer = shadowContainer.attachShadow({ mode: 'open' });
    } else {
        finalContainer = container;
    }

    await insertSDKStyleSheetElement({
        container: finalContainer
    });

    const finalElement = h(
        ErrorBoundary, null, element
    );

    preactRender(finalElement, finalContainer);

    return {
        destroy: () => {
            if (shadowContainer) {
                shadowContainer.remove();
            }

            preactRender(null, finalContainer);
        }
    };
};

export { h as element } from 'preact';
export { useEffect, useState } from 'preact/hooks';

export { default as clsx } from 'clsx';

export { default as deepmerge } from 'deepmerge';
