/** @jsx h */

import { Fragment, h } from 'preact';

import {
    ConsentPopupButton as Button, ConsentPopupColumn as Column, ConsentPopupColumns as Columns,
    ConsentPopupEmailField as EmailField, ConsentPopupImage as PopupImage, ConsentPopupLogo as Logo,
    ConsentPopupPage as Page, ConsentPopupPhoneField as PhoneField, ConsentPopupQuizComponent as Quiz,
    ConsentPopupRow as Row, ConsentPopupTerms as Terms, ConsentPopupText as TextContent
} from '../../components';

import type { PopupTemplatePage, PopupTemplateStackElement } from '@onetext/api';
import { ELEMENT_TYPE } from '@onetext/api';

export type TemplatePageProps = {
    page : PopupTemplatePage,
};

export const TemplatePage = ({ page } : TemplatePageProps) : JSX.Element => {

    const renderPageElements = (element : PopupTemplateStackElement) : JSX.Element => {
        switch (element.type) {
            case ELEMENT_TYPE.BUTTON:
                return <Button overrideStyles={ element.style }>{ element.content }</Button>;

            case ELEMENT_TYPE.TEXT:
                return (
                    <TextContent
                        content={ element.content }
                        overrideStyles={ element.style }
                    />
                );

            case ELEMENT_TYPE.EMAIL_FIELD:
                return <EmailField overrideStyles={ element.style } />;

            case ELEMENT_TYPE.PHONE_FIELD:
                return <PhoneField overrideStyles={ element.style } />;

            case ELEMENT_TYPE.TERMS:
                return (
                    <Terms
                        overrideStyles={ element.style }
                    />
                );

            case ELEMENT_TYPE.LOGO:
                return <Logo overrideStyles={ element.style } />;

            case ELEMENT_TYPE.IMAGE:
                return (
                    <PopupImage element={ element } />
                );
            case ELEMENT_TYPE.QUIZ:
                return (
                    <Quiz element={ element } />
                );

            default:
                throw new Error(`Unknown element type: ${ element.type }`);
        }
    };

    const Wrapper = page.stacks.length && page.stacks.length > 1
        ? Columns
        : Fragment;

    return (
        <Page openKeyboardOnSubmit>
            <Row>
                <Wrapper>
                    {
                        page.stacks.map((stack) => {
                            return (
                                <Column overrideStyles={ stack.style }>
                                    { stack.elements.map(renderPageElements) }
                                </Column>
                            );

                        })
                    }
                </Wrapper>
            </Row>
        </Page>
    );
};
