import type { POPUP_VIEWPORT_MODE } from '../../constants';
/** @jsx onetext.jsx.element */
import type { OneText } from '../../global';
import type { RenderReturnType } from '../../jsx';

import { getLivePopup } from '../../api';
import { render } from '../../jsx';
import { getSDKPopupID, isRayconPopup, trackWebsiteView } from '../../lib';

import { getPopupComponent, onPopupRegistration } from './registry';
import { PopupTemplate } from './template';

import type { GetPopupAPIResponse, HTTPUrl } from '@onetext/api';

declare let onetext : OneText;

export type PopupOptions = {
    popup ?: GetPopupAPIResponse,
    containerElement ?: HTMLElement,
    popupViewportMode ?: POPUP_VIEWPORT_MODE,
};

export const renderPopup = async (options ?: PopupOptions) : Promise<RenderReturnType | undefined> => {
    const containerElement = options?.containerElement ?? document.body;

    let popup : GetPopupAPIResponse;

    if (options?.popup) {
        popup = options.popup;
    } else {
        const externalIDOverride = getSDKPopupID();
        const livePopup = await getLivePopup({
            externalIDOverride,
            url: window.location.href as HTTPUrl
        });

        if (!livePopup.popupFound) {
            trackWebsiteView();
            return;
        }

        popup = livePopup;
    }

    const { externalID, token } = popup;

    // Skip tracking for Raycon popup as it will be tracked from the component itself with correct token
    const shouldSkipTracking = isRayconPopup(token);

    if (!shouldSkipTracking) {
        trackWebsiteView({ popupToken: token });
    }

    if (externalID) {
        const PopupComponent = getPopupComponent(externalID);

        if (PopupComponent) {
            return render({
                element:   <PopupComponent popup={ popup } />,
                container: containerElement
            });
        }

        onPopupRegistration((registeredID) => {
            if (registeredID === externalID) {
                const RegisteredComponent = getPopupComponent(externalID);

                if (RegisteredComponent) {
                    void render({
                        element:   <RegisteredComponent popup={ popup } />,
                        container: containerElement
                    });
                }
            }
        });

        return;
    }

    return render({
        element: <PopupTemplate
            popup={ popup }
            popupViewportMode={ options?.popupViewportMode } />,
        container: containerElement
    });
};
